import { Col, Container, Row } from "react-bootstrap";
import AppModal from "../app-modal/app-modal";
import AppButton, { ButtonVariant } from "../buttons/app-button/app-button";
import MaterialIcon from "../material-icon/material-icon";

export interface ModelContainerProps {
  show: boolean;
  title?: string;
  size?: "modal-sm" | "modal-lg" | "modal-md";
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
  children?: JSX.Element;
  hideCancel?: boolean;
  isLoading?: boolean;
  className?: string;
}

const ModalContainer = (props: ModelContainerProps) => {
  const { isLoading = false } = props;
  return (
    <AppModal show={props.show} size={props.size}>
      <Container fluid className="p-0">
        <Row className="justify-content-between">
          <Col xs="10">
            <div
              className="font-size-22"
              style={{
                fontWeight: 500,
                fontSize: "24px",
                color: "#505662",
              }}
            >
              {props.title}
            </div>
          </Col>
          <Col xs="2" sm="auto" className="d-flex align-items-center fw-bold">
            <MaterialIcon
              icon="close"
              className="cursor-pointer"
              onClick={props.onClose}
            />
          </Col>
        </Row>
        <Row className={`${props.className ? props.className : `mt-4`}`}>
          <Col xs={12}>{props.children}</Col>
        </Row>
        <Row className="mt-5">
          <Col xs="auto">
            <AppButton
              text={props.confirmButtonText || "Confirm"}
              variant={props.confirmButtonVariant || "blue"}
              size="medium"
              onClick={props.onConfirm}
              isLoading={isLoading}
            />
          </Col>
          {!props.hideCancel && (
            <Col xs="auto" className="p-0">
              <AppButton
                text={props.cancelButtonText || "Cancel"}
                variant="transparent"
                onClick={props.onCancel}
                size="medium"
                className="px-2"
              />
            </Col>
          )}
        </Row>
      </Container>
    </AppModal>
  );
};

export default ModalContainer;
