import { Col, Row, Spinner } from "react-bootstrap";
import styles from "./app-save.module.scss";

const AppSave = () => {
  return (
    <Row className={`${styles.spinnerContainer} g-1 align-items-center`}>
      <Col xs="6">
        <Spinner
          className={styles.spinner}
          animation="border"
          variant="secondary"
        />
      </Col>
      <Col xs="6">
        <span className={styles.text}>Saving</span>
      </Col>
    </Row>
  );
};

export default AppSave;
