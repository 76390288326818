import { format } from "date-fns";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./app-date-picker.module.scss";
interface IProps {
  selectedDate: Date;
  dateFormat?: string;
  onChange: (date: Date) => void;
  monthYearPicker?: boolean;
  yearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isInput?: boolean;
  fontSize?: number;
}

const AppDatePicker = (props: IProps) => {
  const {
    dateFormat = "yyyy MMMM",
    selectedDate,
    monthYearPicker,
    yearPicker,
    minDate,
    maxDate,
    onChange,
    isInput,
    fontSize,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    onChange(date);
  };

  const previousMonth = () => {
    if (yearPicker) {
      onChange(
        new Date(selectedDate.setFullYear(selectedDate.getFullYear() - 1))
      );
      return;
    } else if (monthYearPicker) {
      onChange(new Date(selectedDate.setMonth(selectedDate.getMonth() - 1)));
      return;
    }
    onChange(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const nextMonth = () => {
    if (yearPicker) {
      onChange(
        new Date(selectedDate.setFullYear(selectedDate.getFullYear() + 1))
      );
      return;
    } else if (monthYearPicker) {
      onChange(new Date(selectedDate.setMonth(selectedDate.getMonth() + 1)));
      return;
    }
    onChange(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="month-selection">
        <Row className="justify-content-between align-items-center flex-nowrap w-100">
          {isInput ? (
            <>
              <Col className="col-auto px-2 font-size-14">
                <div style={{ fontSize: `${fontSize ? fontSize + "px" : ""}` }}>
                  {format(selectedDate, "E(dd), MMM, yyyy")}
                </div>
              </Col>
              <Col className="col-auto px-2">
                <MaterialIcon
                  onClick={handleClick}
                  icon="calendar_month"
                  className="text-light"
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </>
          ) : (
            <>
              {minDate && selectedDate.getMonth() === minDate.getMonth() ? (
                <></>
              ) : (
                <Col className="col-auto px-1">
                  <MaterialIcon
                    icon="chevron_left"
                    className="text-light"
                    style={{ cursor: "pointer" }}
                    onClick={previousMonth}
                  />
                </Col>
              )}
              <Col className="text-center cursor-pointer font-size-10">
                <div
                  style={{ fontSize: `${fontSize ? fontSize + "px" : ""}` }}
                  onClick={handleClick}
                >
                  {format(selectedDate, dateFormat)}
                </div>
              </Col>
              {maxDate && selectedDate.getMonth() === maxDate.getMonth() ? (
                <></>
              ) : (
                <Col className="col-auto px-1">
                  <MaterialIcon
                    icon="chevron_right"
                    className="text-light"
                    style={{ cursor: "pointer" }}
                    onClick={nextMonth}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
      <div className="position-relative">
        <div className={`${styles.datePicker} ${isInput && styles.isInput}`}>
          {isOpen && (
            <DatePicker
              selected={selectedDate}
              onChange={handleChange}
              inline
              onClickOutside={handleClick}
              showMonthYearPicker={monthYearPicker}
              showYearPicker={yearPicker}
              calendarStartDay={1}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AppDatePicker;
