import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import Select, { MultiValue, SingleValue } from "react-select";
import styles from "./app-select.module.scss";

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> =
  DeepMap<TFieldValues, FieldError>;

export interface Option {
  value: string;
  label: string | JSX.Element;
  data?: any;
}

interface IProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  errorMessage?: string;
  label?: string;
  onIconClick?: () => void;
  onSelectOption?: (option: Option) => void;
  options: Option[];
  register?: UseFormRegisterReturn;
  errors?: FieldErrors;
  control?: Control<any, any>;
  isMultiSelect?: boolean;
  onChangeOption?: (option: Option) => void;
  selectedValue?: Option | Option[] | undefined;
  borderRadiusStyle?: "Normal" | "Right";
  menuHeight?: string;
  isTimeSelection?: boolean;
  fontSize?: string;
  labelFontSize?: string;
  fontWeight?: string;
  fontColor?: string;
}

const AppSelect = (props: IProps) => {
  const {
    className,
    fontSize,
    labelFontSize,
    fontWeight,
    fontColor,
    label,
    options,
    errors,
    isMultiSelect,
    control,
    onChangeOption,
    selectedValue,
    borderRadiusStyle = "Normal",
    menuHeight = "300px",
    disabled,
    isTimeSelection,
    ...rest
  } = props;

  const style = {
    control: (base: any) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      borderRadius: "12px",
      height: "38px",
      cursor: "pointer",
      backgroundColor: disabled ? "none" : "",
      opacity: disabled ? "0.5" : "",
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: fontSize,
      fontWeight: fontWeight,
      color: fontColor,
    }),
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: "#69768B",
      backgroundColor: state.isSelected ? "#dddddd22" : null,
      "&:hover": {
        backgroundColor: "#dddddd22",
        color: "#69768B",
      },
      borderBottom: isTimeSelection && "0.5px solid #ECF1FF",
    }),
    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      borderRadius: "8px",
      boxShadow: "0px 6px 16px 0px rgba(193, 193, 193, 0.25)",
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: menuHeight,
      overflow: "auto",
      fontSize: fontSize,
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "#69768B",
      backgroundColor: " #F6F6F6",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "#69768B",
      backgroundColor: " #F6F6F6",
      height: 30,
      ":hover": {
        color: "#ffffff",
      },
    }),
  };

  return (
    <div
      className={`app-input-wrapper ${className} ${
        disabled ? `cursor-disabled` : ``
      }`}
    >
      {label && (
        <label
          className={`${disabled ? styles.opacity : ``} ${
            labelFontSize ? labelFontSize : `font-size-16`
          }`}
        >
          {label}
        </label>
      )}
      <div
        className={`${styles.appSelectInput} ${
          rest.name && errors && errors[rest.name]?.message && styles.hasDanger
        }`}
        style={
          borderRadiusStyle === "Right"
            ? { borderRadius: "12px 0 0 12px" }
            : { borderRadius: "12px" }
        }
      >
        {control ? (
          <Controller
            name={rest.name as string}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                styles={style}
                isMulti={isMultiSelect}
                placeholder={rest.placeholder}
                components={
                  isTimeSelection
                    ? {
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }
                    : { IndicatorSeparator: () => null }
                }
                isDisabled={disabled}
              />
            )}
          />
        ) : (
          <Select
            options={options}
            styles={style}
            isMulti={isMultiSelect}
            placeholder={rest.placeholder}
            components={
              isTimeSelection
                ? {
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }
                : { IndicatorSeparator: () => null }
            }
            onChange={(newValue: SingleValue<Option> | MultiValue<Option>) => {
              const option: Option = newValue as Option;
              onChangeOption && onChangeOption(option);
            }}
            value={selectedValue}
            isDisabled={disabled}
          />
        )}
      </div>
      {rest.name && (
        <span className="error">{errors && errors[rest.name]?.message}</span>
      )}
    </div>
  );
};

export default AppSelect;
