import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../oversight-core/ui-elements/modal-container/modal-container";
import ScheduleSelectedIndicator from "../../oversight-core/ui-elements/schedule-select-indicator/schedule-select-indicator";
import styles from "./schedule-save-model.module.scss";

type IProps = ModelContainerProps;

const ScheduleSaveUsageModal = (props: IProps) => {
  return (
    <ModalContainer
      {...props}
      title="Save Usage Schedule"
      confirmButtonText="Save"
      size="modal-lg"
    >
      <div className={styles.scheduleUsageModalContainer}>
        <p className={styles.mainDescription}>
          You haven&apos;t added all usage data far all the spaces for week and
          weekend days. You can save and complete the rest later or you can add
          them right now by finding non scheduled spaces.
        </p>
        <p className={`${styles.secondDescription}`}>
          You can easily find the spaces yet to be schedules using the indicator
          colors in front of the space name.
        </p>
        <Row className={styles.firstRow}>
          <Col
            xs="auto"
            className={`${styles.indItem} d-flex align-items-center pe-0`}
          >
            <div className={`${styles.indicator} ${styles.weekDay} me-2`} />
            Weekdays Scheduled
          </Col>
          <Col
            xs="auto"
            className={`${styles.indItem} d-flex align-items-center pe-0`}
          >
            <div className={`${styles.indicator} ${styles.weekEnd} me-2`} />
            Weekends Scheduled{" "}
          </Col>
          <Col
            xs="auto"
            className={`${styles.indItem} d-flex align-items-center pe-0`}
          >
            <div
              className={`${styles.indicator} ${styles.weekNotSchedule} me-2`}
            />
            Space Not Scheduled{" "}
          </Col>
        </Row>
        <Row className={styles.secondRow}>
          <Col xs="auto" className="d-flex align-items-center">
            Ex:
          </Col>
          <Col
            xs="auto"
            className={`${styles.spaceLabel} d-flex align-items-center`}
          >
            <div>Bed Room</div>
            <div className="ms-1">
              <ScheduleSelectedIndicator
                isWeekdayFilled={true}
                isWeekendFilled={true}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className={`${styles.spaceLabel2} d-flex align-items-center`}
          >
            <div>Rented Space</div>
            <div className="ms-1">
              <ScheduleSelectedIndicator
                isWeekdayFilled={false}
                isWeekendFilled={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    </ModalContainer>
  );
};

export default ScheduleSaveUsageModal;
