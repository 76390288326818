export enum EDeviceTypes {
  AllDevices = "All Devices",
  TV = "TV",
  Refrigerator = "Refrigerator",
  MicrowaveOven = "Microwave Oven",
  Bulb = "Bulb",
  Computer = "Computer",
  HomeTheater = "Home-theater",
  Iron = "Iron",
  WashingMachine = "Washing Machine",
  AirConditioner = "Air Conditioner",
  Fan = "Fan",
}
