import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  useAddUserMutation,
  useLazyGetUserValidationQuery,
} from "../../../redux/api/user/userAPI";
import useDebounce from "../../../shared/hooks/useDebounce";
import RegisteringUsersDTO from "../../../shared/oversight-core/dtos/registering-users-dto";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import ValidateRegisterInputResponseDTO from "../../../shared/oversight-core/response-dto/validate-user-response-dto";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import CheckBox from "../../../shared/oversight-core/ui-elements/check-box/check-box";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import {
  emailRegex,
  mobileNumberRegex,
  nicRegex,
  noOnlySpecialCharsNoWhitespaceStartEndRegex,
  noSpecialCharsNoWhitespaceStartEndRegex,
  passwordRegex,
} from "../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";

interface IFormInput extends RegisteringUsersDTO {
  termsAndConditions: boolean;
  confirmPassword: string;
}

const defaultFormValues: IFormInput = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  mobileNumber: "",
  password: "",
  confirmPassword: "",
  NIC: "",
  termsAndConditions: false,
};

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [nicError, setNICError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const password = useRef({});
  password.current = watch("password", "");
  const username = watch("username");
  const email = watch("email");
  const mobileNumber = watch("mobileNumber");
  const nic = watch("NIC");

  const [AddUser, { isLoading: isLoadingAddUser }] = useAddUserMutation();
  const [triggerGetUserValidation] = useLazyGetUserValidationQuery();

  const debouncedUsername = useDebounce(username, 1000);
  const debouncedNIC = useDebounce(nic, 1000);
  const debouncedMobileNumber = useDebounce(mobileNumber, 1000);
  const debouncedEmail = useDebounce(email, 1000);

  useEffect(() => {
    if (
      debouncedUsername &&
      debouncedUsername.length > 5 &&
      debouncedUsername.length < 21
    ) {
      triggerGetUserValidation({
        username: debouncedUsername,
      })
        .unwrap()
        .then((response: ValidateRegisterInputResponseDTO) => {
          setUsernameError(!response.validations.username.CEB);
        })
        .catch(() => {
          setUsernameError(true);
        });
    }
  }, [triggerGetUserValidation, debouncedUsername]);

  useEffect(() => {
    if (
      debouncedMobileNumber &&
      (debouncedMobileNumber.length === 10 ||
        debouncedMobileNumber.length === 12)
    ) {
      triggerGetUserValidation({
        mobileNumber: debouncedMobileNumber,
      })
        .unwrap()
        .then((response: ValidateRegisterInputResponseDTO) => {
          setMobileNumberError(!response.validations?.mobile_number?.CEB);
        })
        .catch(() => {
          setMobileNumberError(true);
        });
    }
  }, [triggerGetUserValidation, debouncedMobileNumber]);

  useEffect(() => {
    if (debouncedEmail && emailRegex.test(debouncedEmail)) {
      triggerGetUserValidation({
        email: debouncedEmail,
      })
        .unwrap()
        .then((response: ValidateRegisterInputResponseDTO) => {
          setEmailError(!response.validations.email.CEB);
        })
        .catch(() => {
          setEmailError(true);
        });
    }
  }, [triggerGetUserValidation, debouncedEmail]);

  useEffect(() => {
    if (debouncedNIC && nicRegex.test(debouncedNIC)) {
      triggerGetUserValidation({
        nic: debouncedNIC,
      })
        .unwrap()
        .then((response: ValidateRegisterInputResponseDTO) => {
          setNICError(!response.validations.nic.CEB);
        })
        .catch(() => {
          setNICError(true);
        });
    }
  }, [triggerGetUserValidation, debouncedNIC]);

  useEffect(() => {
    if (!emailError && !mobileNumberError && !usernameError && !nicError) {
      triggerGetUserValidation({
        email: debouncedEmail,
        mobileNumber: debouncedMobileNumber,
        username: debouncedUsername,
        nic: debouncedNIC,
      })
        .unwrap()
        .then((response: ValidateRegisterInputResponseDTO) => {
          setEmailError(response.validations.email.LECO);
          setMobileNumberError(response.validations.mobile_number.LECO);
          setUsernameError(response.validations.username.LECO);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    triggerGetUserValidation,
    emailError,
    mobileNumberError,
    usernameError,
    nicError,
  ]);

  const onSubmit = (data: IFormInput) => {
    const { confirmPassword, ...newData } = data;
    if (
      !usernameError &&
      !mobileNumberError &&
      !emailError &&
      !nicError &&
      confirmPassword === data.password
    ) {
      AddUser({
        ...newData,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("User Registration Successful");
          reset({ ...defaultFormValues });
          navigate(AppRoute.MOBILE_NUMBER_VERIFICATION, {
            state: {
              number: data.mobileNumber,
              whereTo: "dashboard",
            },
          });
        })
        .catch(() => {
          showErrorMessage("User Registration Unsuccessful");
        });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorStyle(false);
    } else {
      setErrorStyle(true);
    }
  }, [errors]);

  return (
    <Row className="mt-4">
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <AppInput
                name="firstName"
                placeholder="First Name"
                label="First Name*"
                register={register("firstName", {
                  required: "First name is required",
                  minLength: {
                    value: 2,
                    message: "This field must contain at least 2 characters",
                  },
                  maxLength: {
                    value: 50,
                    message:
                      "You have exceeded the maximum number of 50 characters in this field",
                  },
                  pattern: {
                    value: noSpecialCharsNoWhitespaceStartEndRegex,
                    message:
                      "Entered value can't start/end or contain only white spaces and can't contain any special characters",
                  },
                })}
                errors={errors}
              />
            </Col>
            <Col>
              <AppInput
                name="lastName"
                placeholder="Last Name"
                label="Last Name*"
                register={register("lastName", {
                  required: "Last name is required",
                  minLength: {
                    value: 2,
                    message: "This field must contain at least 2 characters",
                  },
                  maxLength: {
                    value: 50,
                    message:
                      "You have exceeded the maximum number of 50 characters in this field",
                  },
                  pattern: {
                    value: noSpecialCharsNoWhitespaceStartEndRegex,
                    message:
                      "Entered value can't start/end or contain only white spaces and can't contain any special characters",
                  },
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <AppInput
            className="mt-3"
            name="username"
            placeholder="Username"
            label="Username*"
            icon={
              debouncedUsername.length > 5 &&
              debouncedUsername.length < 21 &&
              !usernameError &&
              noOnlySpecialCharsNoWhitespaceStartEndRegex.test(username)
                ? "check"
                : undefined
            }
            register={register("username", {
              required: "Username is required",
              minLength: {
                value: 6,
                message: "This field must contain at least 6 characters",
              },
              maxLength: {
                value: 20,
                message:
                  "You have exceeded the maximum number of 20 characters in this field",
              },
              pattern: {
                value: noOnlySpecialCharsNoWhitespaceStartEndRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain only special characters",
              },
            })}
            errors={errors}
            isSuccess={
              debouncedUsername.length > 5 &&
              debouncedUsername.length < 21 &&
              !usernameError &&
              noOnlySpecialCharsNoWhitespaceStartEndRegex.test(
                debouncedUsername
              )
            }
            isError={
              debouncedUsername.length > 5 &&
              debouncedUsername.length < 21 &&
              usernameError &&
              noOnlySpecialCharsNoWhitespaceStartEndRegex.test(
                debouncedUsername
              )
            }
          />
          {debouncedUsername.length > 5 &&
            debouncedUsername.length < 21 &&
            usernameError && (
              <p className="error">
                Username already exists. please use a different name
              </p>
            )}
          {username.length > 5 &&
            username.length < 21 &&
            !noOnlySpecialCharsNoWhitespaceStartEndRegex.test(username) && (
              <p className="error">
                Username cannot contain only special characters and start and
                end while spaces
              </p>
            )}
          <AppInput
            className="mt-3"
            name="NIC"
            placeholder="NIC"
            label="NIC*"
            icon={
              nicRegex.test(debouncedNIC) && !nicError ? "check" : undefined
            }
            register={register("NIC", {
              required: "NIC is required",
              pattern: {
                value: nicRegex,
                message: "Please enter a valid NIC",
              },
            })}
            errors={errors}
            isSuccess={nicRegex.test(debouncedNIC) && !nicError}
            isError={nicRegex.test(debouncedNIC) && nicError}
          />
          {nicRegex.test(debouncedNIC) && nicError && (
            <p className="error">
              NIC already exists. please use a different NIC
            </p>
          )}
          <AppInput
            className="mt-3"
            name="mobileNumber"
            placeholder="Mobile Number"
            label="Mobile Number*"
            type="text"
            icon={
              (debouncedMobileNumber.length === 10 ||
                debouncedMobileNumber.length === 12) &&
              !mobileNumberError
                ? "check"
                : undefined
            }
            register={register("mobileNumber", {
              required: "Mobile number is required",
              pattern: {
                value: mobileNumberRegex,
                message: "Mobile number is invalid",
              },
            })}
            errors={errors}
            isSuccess={
              (debouncedMobileNumber.length === 10 ||
                debouncedMobileNumber.length === 12) &&
              !mobileNumberError
            }
            isError={
              (debouncedMobileNumber.length === 10 ||
                debouncedMobileNumber.length === 12) &&
              mobileNumberError
            }
          />
          {(debouncedMobileNumber.length === 10 ||
            debouncedMobileNumber.length === 12) &&
            mobileNumberError && (
              <p className="error">
                Mobile number already exists. please use a different number
              </p>
            )}
          <AppInput
            className="mt-3"
            name="email"
            placeholder="Enter your email"
            label="Email (optional)"
            icon={
              emailRegex.test(debouncedEmail) && !emailError
                ? "check"
                : undefined
            }
            register={register("email" || "", {
              pattern: {
                value: emailRegex,
                message: "Please enter a valid email",
              },
            })}
            errors={errors}
            isSuccess={emailRegex.test(debouncedEmail) && !emailError}
            isError={emailRegex.test(debouncedEmail) && emailError}
          />
          {emailRegex.test(debouncedEmail) && emailError && (
            <p className="error">
              Email already exists. please use a different email
            </p>
          )}
          <Row>
            <Col md={6} lg={12} xl={5}>
              <AppInput
                className="mt-3"
                name="password"
                icon={!showPassword ? "visibility_off" : "visibility"}
                placeholder="Password"
                onIconClick={() => setShowPassword(!showPassword)}
                label="Password*"
                type={showPassword ? "text" : "password"}
                register={register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must contain at least 6 characters",
                  },
                  maxLength: {
                    value: 20,
                    message: "Password must contain less than 20 characters",
                  },
                  pattern: {
                    value: passwordRegex,
                    message:
                      "Password must have 6-20 characters and include at least one lowercase letter, one uppercase letter, one numeric and one special character",
                  },
                })}
                errors={errors}
                newPassword={true}
              />
            </Col>
            <Col>
              <AppInput
                className="mt-3"
                name="confirmPassword"
                icon={!showConfirmPassword ? "visibility_off" : "visibility"}
                placeholder="Confirm Password"
                onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                label="Confirm Password*"
                type={showConfirmPassword ? "text" : "password"}
                register={register("confirmPassword", {
                  required: "Password is required",
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <span className="d-flex align-item-center justify-content-center">
                <CheckBox
                  label=""
                  checked={termsAndConditions}
                  onChange={(checked) => setTermsAndConditions(checked)}
                />
                Accept
                <Link to={"#"} className="mx-1">
                  Terms
                </Link>
                and
                <Link to={"#"} className="mx-1">
                  Conditions
                </Link>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <AppButton
                text="Sign Up"
                className={`mt-4 ${errorStyle && `manual-border-shadow`}`}
                variant="blue"
                isLoading={isLoadingAddUser}
                disabled={!termsAndConditions}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 text-center">
              Already have an account? <Link to={AppRoute.LOGIN}>Login</Link>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default RegistrationForm;
