import { useCallback } from "react";
import { EConsumptionValueComparison } from "../../../enums/consumption-comparison";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import Helper from "../../../utils/helpers";
import styles from "./usage.module.scss";

interface IProps {
  cost: number;
  units: number;
  title: string;
  comparisonWithLastMonth?: EConsumptionValueComparison;
}

const Usage = (props: IProps) => {
  const switchIcon = useCallback(() => {
    switch (props.comparisonWithLastMonth) {
      case EConsumptionValueComparison.HIGH:
        return <MaterialIcon icon="trending_up" color="#E75B74" />;
      case EConsumptionValueComparison.LOW:
        return <MaterialIcon icon="trending_down" color="#59C270" />;
      case EConsumptionValueComparison.EQUAL:
        return (
          <MaterialIcon icon="check_indeterminate_small" color="#819EB9" />
        );
      default:
        return <div className="py-2 mt-2"></div>;
    }
  }, [props.comparisonWithLastMonth]);

  return (
    <div className="container-white h-100 d-flex align-items-center">
      <div className={styles.container}>
        <label className={styles.title}>{props.title}</label>
        <div className={`${styles.value} d-flex align-items-center`}>
          <label>Rs {Helper.roundTo2(props.cost)}</label>
          {switchIcon()}
        </div>
        <label className={styles.units}>
          {" "}
          {Helper.roundTo2(props.units)} Units
        </label>
      </div>
    </div>
  );
};

export default Usage;
