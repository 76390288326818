import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyListPowerConsumersQuery,
  useRemoveDeviceMutation,
} from "../../../redux/api/device/deviceAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyProfileQuery } from "../../../redux/api/user/userAPI";
import {
  selectFilter,
  setFilter,
} from "../../../redux/features/filter/filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import IconButton from "../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import ListCard from "../../../shared/oversight-general-core/components/list-card/list-card";
import SpaceDeviceFilters, {
  ISelectedSpace,
} from "../../../shared/oversight-general-core/components/space-device-filters/space-device-filters";
import {
  IGroupedPowerConsumers,
  IListPowerConsumersGroupedResponseDTO,
  IListPowerConsumersResponseDTO,
  ISpaceClusterPowerConsumerViews,
} from "../../../shared/oversight-general-core/dtos/list-power-consumers-response-dto";
import AddUpdateSpaceModal from "./components/models/add-update-space-modal/add-update-space-modal";
import SpaceContainer from "./components/space-container/space-container";
import styles from "./space-clusters.module.scss";

const SpaceClusters = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [deviceListGrouped, setDeviceListGrouped] = useState<
    IGroupedPowerConsumers[]
  >([]);
  const [deviceListNotGrouped, setDeviceListNotGrouped] = useState<
    ISpaceClusterPowerConsumerViews[]
  >([]);
  const [groupedCollapsed, setGroupedCollapsed] = useState<boolean[]>([]);

  const [
    triggerGetSpaces,
    {
      data: getSpacesData,
      isSuccess: getSpacesSuccess,
      isFetching: loadingSpaces,
    },
  ] = useLazyGetSpacesQuery();

  const [getProfile, { isFetching: isFetchingProfile }] = useLazyProfileQuery();

  useEffect(() => {
    triggerGetSpaces();
  }, [triggerGetSpaces]);

  useEffect(() => {
    if (getSpacesSuccess) {
      const tempArray: ISpaceView[] = [];
      let spaceClusterView = tempArray ? tempArray : [];
      if (getSpacesData) {
        for (const spaceCluster of getSpacesData.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
      }
      spaceClusterView = spaceClusterView.sort((a, b) =>
        a.name.trim().localeCompare(b.name.trim())
      );
      setSpaceClusters([...spaceClusterView]);
    }
  }, [getSpacesSuccess, getSpacesData]);

  const updateCurrentState = async () => {
    await triggerGetSpaces();

    if (profile && profile.serviceProvider === null) {
      await getProfile();
    }
  };

  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };

  const [removeDevice, { isLoading: isLoadingRemoveDevice }] =
    useRemoveDeviceMutation();

  const [
    triggerListPowerConsumers,
    { isFetching: isFetchingListPowerConsumers },
  ] = useLazyListPowerConsumersQuery();

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string
  ) => {
    removeDevice({ clusterId, spaceId, deviceId })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Removed Successfully");
      })
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  useEffect(() => {
    if (filtersStore.selectedGroupBy === "Type") {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      } else {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      }
    } else {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      } else {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      }
    }
  }, [triggerListPowerConsumers, filtersStore, spaceClusters]);

  const findSpace = (
    spaces: ISpaceView[],
    spaceId: string,
    path: string
  ): { space: ISpaceView; path: string } | undefined => {
    const space = spaces.find((spaceItem) => spaceItem.id === spaceId);

    if (space) {
      return { space: space, path: path };
    }
    for (const spaceItem of spaces) {
      return findSpace(
        spaceItem.childSpaces,
        spaceId,
        path + "/" + spaceItem.id
      );
    }
  };

  useEffect(() => {
    if (
      !filtersStore.selectedSpace.clusterId ||
      filtersStore.selectedGroupBy === "Type" ||
      filtersStore.selectedDeviceType
    ) {
      return;
    }

    const cluster = spaceClusters.find(
      (spaceCluster) =>
        spaceCluster.clusterId === filtersStore.selectedSpace.clusterId
    );

    if (cluster && cluster.id === filtersStore.selectedSpace.id) {
      navigate(pathname + "/" + cluster ? cluster.id : "", {
        state: {
          clusterId: cluster ? cluster.clusterId : "",
          spaceId: cluster ? cluster.id : "",
        },
      });
      return;
    }

    const spaceObj = findSpace(
      cluster ? cluster.childSpaces : [],
      filtersStore.selectedSpace.id,
      pathname + "/" + (cluster ? cluster.id : "")
    );

    if (spaceObj) {
      navigate(spaceObj.path + "/" + spaceObj.space.id, {
        state: {
          clusterId: cluster ? cluster.clusterId : "",
          spaceId: spaceObj.space.id,
        },
      });
      return;
    }
  }, [filtersStore, spaceClusters]);

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string
  ) => {
    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
      })
    );
  };

  return (
    <div className={styles.devicesContainer}>
      <div className="mb-4">
        <SpaceDeviceFilters
          updateFilters={updateFilters}
          isLoading={
            loadingSpaces ||
            isLoadingRemoveDevice ||
            isFetchingListPowerConsumers ||
            isFetchingProfile
          }
        />
      </div>
      {!isFetchingListPowerConsumers &&
        filtersStore.selectedGroupBy === "Type" && (
          <>
            {deviceListGrouped.length !== 0 ? (
              <Col>
                {deviceListGrouped.map(
                  (elements: IGroupedPowerConsumers, index) => {
                    return (
                      <Row key={index} className="container-white mx-1 mt-4">
                        <Col>
                          <Row
                            className="cursor-pointer"
                            onClick={() => {
                              setGroupedCollapsed((ps) => {
                                const temp = [...ps];
                                temp[index] = !temp[index];
                                return [...temp];
                              });
                            }}
                          >
                            <Col
                              className={`p-1 ${elements.group} rounded col-auto`}
                            >
                              <MaterialIcon
                                icon={findIcon(elements.group as EDeviceTypes)}
                                size={19}
                              />
                            </Col>
                            <Col className="text-dark font-size-16 font-weight-500">{`${elements.group}s`}</Col>
                            <Col className="col-auto">
                              <MaterialIcon
                                icon="expand_more"
                                className={`cursor-pointer ${styles.dArrow}`}
                                style={
                                  groupedCollapsed[index]
                                    ? { transform: "rotate(-90deg)" }
                                    : { transform: "rotate(0deg)" }
                                }
                              />
                            </Col>
                          </Row>
                          {elements.spaceClusterPowerConsumers.map(
                            (spaceClusterPowerConsumer) => {
                              return (
                                <Row
                                  key={
                                    spaceClusterPowerConsumer.powerConsumer.id
                                  }
                                  className={`${
                                    groupedCollapsed[index] ? "d-none" : ""
                                  } mt-2`}
                                >
                                  <Col className="px-0">
                                    <ListCard
                                      deleteDevice={deleteDevice}
                                      updateCurrentState={updateCurrentState}
                                      device={{
                                        ...spaceClusterPowerConsumer.powerConsumer,
                                        clusterId:
                                          spaceClusterPowerConsumer.spaceClusterId,
                                        spaceId:
                                          spaceClusterPowerConsumer.spaceId,
                                      }}
                                      mainSpaceClusterId={
                                        spaceClusterPowerConsumer.spaceClusterId
                                      }
                                      mainSpaceId={
                                        spaceClusterPowerConsumer.spaceId
                                      }
                                      deviceStatus={
                                        spaceClusterPowerConsumer.powerConsumer
                                          .isOn
                                      }
                                      isDevicesOnly={true}
                                      path={spaceClusterPowerConsumer.path}
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                    );
                  }
                )}
              </Col>
            ) : (
              <Row className="container-white mx-1 mt-4">
                <div className="container-dash mt-4">
                  <Col className="text-center text-light font-size-12">
                    There are no devices available for the selected filters.
                  </Col>
                </div>
              </Row>
            )}
          </>
        )}

      {!isFetchingListPowerConsumers &&
        filtersStore.selectedGroupBy !== "Type" &&
        (filtersStore.selectedDeviceType || filtersStore.searchText) && (
          <Row className="container-white mx-1 mt-4">
            {deviceListNotGrouped.length !== 0 ? (
              <Col>
                {filtersStore.selectedDeviceType && (
                  <Row className="align-items-center cursor-pointer">
                    <Col
                      className={`p-1 ${filtersStore.selectedDeviceType} rounded col-auto`}
                    >
                      <MaterialIcon
                        icon={findIcon(
                          filtersStore.selectedDeviceType as EDeviceTypes
                        )}
                        size={19}
                      />
                    </Col>
                    <Col className="text-dark font-size-16 font-weight-500">{`${filtersStore.selectedDeviceType}s`}</Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    {deviceListNotGrouped.map(
                      (
                        spaceClusterPowerConsumer: ISpaceClusterPowerConsumerViews
                      ) => {
                        return (
                          <Row
                            key={spaceClusterPowerConsumer.powerConsumer.id}
                            className="mt-2"
                          >
                            <Col>
                              <ListCard
                                deleteDevice={deleteDevice}
                                updateCurrentState={updateCurrentState}
                                device={{
                                  ...spaceClusterPowerConsumer.powerConsumer,
                                  clusterId:
                                    spaceClusterPowerConsumer.spaceClusterId,
                                  spaceId: spaceClusterPowerConsumer.spaceId,
                                }}
                                mainSpaceClusterId={
                                  spaceClusterPowerConsumer.spaceClusterId
                                }
                                mainSpaceId={spaceClusterPowerConsumer.spaceId}
                                deviceStatus={
                                  spaceClusterPowerConsumer.powerConsumer.isOn
                                }
                                isDevicesOnly={true}
                                path={spaceClusterPowerConsumer.path}
                              />
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Col>
                </Row>
              </Col>
            ) : (
              <div className="container-dash mt-4">
                <Col className="text-center text-light font-size-12">
                  There are no devices available for the selected filters.
                </Col>
              </div>
            )}
          </Row>
        )}

      {!filtersStore.selectedDeviceType &&
        !filtersStore.searchText &&
        filtersStore.selectedGroupBy === `Space` &&
        spaceClusters.map((space) => {
          return (
            <SpaceContainer
              key={space.id}
              mainSpace={space}
              expandable={true}
              isShowIcon={true}
              size={16}
              updateCurrentState={updateCurrentState}
              collapsed
              onSelectSpace={() => {
                navigate(pathname + "/" + space.id, {
                  state: {
                    clusterId: space.clusterId,
                    spaceId: space.id,
                  },
                });
              }}
            />
          );
        })}

      {!filtersStore.selectedDeviceType &&
        !filtersStore.searchText &&
        filtersStore.selectedGroupBy === `Space` &&
        !filtersStore.selectedSpace.clusterId && (
          <IconButton
            icon="add"
            text="Create Billing Space"
            className="mt-4"
            onClick={() => setShowAddUpdateSpaceModal(true)}
          />
        )}

      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceCreationType={"spaceCluster"}
        updateCurrentState={updateCurrentState}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
        spaceClusterId={""}
        parentSpaceId={""}
      />

      <SpinnerModal
        show={
          loadingSpaces ||
          isLoadingRemoveDevice ||
          isFetchingListPowerConsumers ||
          isFetchingProfile
        }
      />
    </div>
  );
};

export default SpaceClusters;
