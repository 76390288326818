import { ReactNode } from "react";
import styles from "./check-box.module.scss";

interface IProps {
  label: string | ReactNode;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const CheckBox = (props: IProps) => {
  return (
    <label className={`${[styles.checkBoxContainer].join(" ")}`}>
      <span>{props.label}</span>
      <input
        type="checkbox"
        defaultChecked={props.checked}
        onChange={(e) => props.onChange && props.onChange(e.target.checked)}
        disabled={props.disabled}
      />
      <span
        className={`${styles.checkmark} ${
          props.disabled ? `app-button-disabled` : ``
        }`}
      ></span>
    </label>
  );
};

export default CheckBox;
