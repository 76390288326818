import { ButtonGroup, Col, Row } from "react-bootstrap";
import styles from "./app-tabs.module.scss";

export interface TabComponentInterface {
  buttonName: string;
  selectedColor: "lightBlue" | "lightGreen" | "purpleColor" | "tabButtonWhite";
  id: number;
}

interface IProps {
  buttons: TabComponentInterface[];
  onChange: (id: number) => void;
  selectedTabId: number;
}

const AppTabs = (props: IProps) => {
  const { buttons, onChange, selectedTabId } = props;

  return (
    <ButtonGroup className={`${styles.tabButtonGroup}`}>
      <Row className="ps-3 w-100 flex-nowrap">
        {buttons.map((button) => {
          return (
            <Col
              key={button.id}
              className={`${styles.tabButton} ${
                selectedTabId === button.id && styles[button.selectedColor]
              } font-size-12 px-3`}
              onClick={() => {
                onChange(button.id);
              }}
            >
              {button.buttonName}
            </Col>
          );
        })}
      </Row>
    </ButtonGroup>
  );
};

export default AppTabs;
