import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useLazyGetSpaceHierarchyQuery,
  useLazyGetSpaceQuery,
} from "../../../../../redux/api/space/spaceAPI";
import {
  selectFilter,
  setFilter,
} from "../../../../../redux/features/filter/filter-slice";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import SpaceDeviceFilters, {
  ISelectedSpace,
} from "../../../../../shared/oversight-general-core/components/space-device-filters/space-device-filters";
import SpaceBreadcrumb, {
  SpaceBreadCrumbPath,
} from "../space-breadcrumb/space-breadcrumb";
import SpaceContainer from "../space-container/space-container";

export const initialBreadcrumbPath: SpaceBreadCrumbPath = {
  clusterId: "",
  id: "",
  name: "All Billing Spaces",
};

const SpaceCluster = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [space, setSpace] = useState<ISpaceView[]>([]);
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [breadCrumbPath, setBreadCrumbPath] = useState<SpaceBreadCrumbPath[]>([
    initialBreadcrumbPath,
  ]);

  const [
    triggerGetSpace,
    {
      data: spaceData,
      isSuccess: getSpaceSuccess,
      isLoading: isLoadingSpace,
      isFetching: isFetchingSpace,
    },
  ] = useLazyGetSpaceQuery();

  const [
    triggerGetHierarchy,
    {
      data: hierarchyData,
      isFetching: isFetchingHierarchy,
      isSuccess: isHierarchySuccess,
    },
  ] = useLazyGetSpaceHierarchyQuery();

  useEffect(() => {
    if (state) {
      setClusterId(state.clusterId);
      setSpaceId(state.spaceId);
    }
  }, [state]);

  useEffect(() => {
    if (clusterId && spaceId) {
      triggerGetSpace({ clusterId, spaceId });
      triggerGetHierarchy({ clusterId, spaceId });
    }
  }, [triggerGetSpace, triggerGetHierarchy, clusterId, spaceId]);

  useEffect(() => {
    if (getSpaceSuccess && spaceData) {
      setSpace([
        {
          ...spaceData.subSpaceCluster.rootSpace,
          clusterId: spaceData.subSpaceCluster.id,
          accountNumber:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.accountNumber
              : "",
          accountNumberLabel:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.label
              : "",
        },
      ]);
    }
  }, [getSpaceSuccess, spaceData]);

  useEffect(() => {
    if (isHierarchySuccess && hierarchyData) {
      const paths: SpaceBreadCrumbPath[] = hierarchyData.ancestorSpaces
        .slice()
        .reverse()
        .map((ancestor) => {
          return {
            clusterId: hierarchyData.id,
            id: ancestor.id,
            name: ancestor.name,
          };
        });

      setBreadCrumbPath(paths);
    }
  }, [isHierarchySuccess, hierarchyData]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      navigate(pathname + "/" + selectedSpace.id, {
        state: {
          clusterId: selectedSpace.clusterId,
          spaceId: selectedSpace.id,
        },
      });
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string
  ) => {
    const currentClusterId = filtersStore.selectedSpace.clusterId;
    const currentSpaceId = filtersStore.selectedSpace.id;

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
      })
    );
    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedGroupBy !== "Space"
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  };

  return (
    <div>
      <SpaceDeviceFilters
        updateFilters={updateFilters}
        isLoading={isLoadingSpace || isFetchingSpace || isFetchingHierarchy}
      />
      {space && (
        <div className={`bg-white rounded-3 p-2 p-md-4 mt-4`}>
          <div className="px-1 pt-2">
            <SpaceBreadcrumb path={breadCrumbPath} />
          </div>

          {!isFetchingSpace && !isLoadingSpace && !spaceData && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  Cannot find the space. Go to{" "}
                  <Link to={"/dashboard/space-clusters"}>
                    All Billing Spaces.
                  </Link>
                </Col>
              </Row>
            </div>
          )}

          {space.map((space) => {
            return (
              <SpaceContainer
                key={space.id}
                mainSpace={space}
                expandable={true}
                isShowIcon={true}
                size={16}
                updateCurrentState={updateCurrentState}
                collapsed={false}
                onSelectSpace={selectSpaceHandler}
              />
            );
          })}
        </div>
      )}
      <SpinnerModal
        show={isLoadingSpace || isFetchingSpace || isFetchingHierarchy}
      />
    </div>
  );
};

export default SpaceCluster;
