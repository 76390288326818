import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { EDeviceTypes } from "../enums/device-types";
import MaterialIcon from "../ui-elements/material-icon/material-icon";
import findIcon from "./findIcon";

export const getDeviceLabel = (value: EDeviceTypes, withIcons: boolean) => {
  if (!withIcons) {
    return value;
  }

  return (
    <Row key={uuidv4()} className="align-items-center flex-nowrap">
      <Col className={`col-auto pe-0`} key={uuidv4()}>
        <div className={`p-1 ${value} rounded`}>
          <MaterialIcon icon={findIcon(value)} size={19} />
        </div>
      </Col>
      <Col key={uuidv4()}>{value}</Col>
    </Row>
  );
};

const getDeviceTypes = (
  withIcons = false
): { value: string; label: JSX.Element | string }[] => {
  const deviceTypes = [
    { value: "TV", label: getDeviceLabel(EDeviceTypes.TV, withIcons) },
    {
      value: "Refrigerator",
      label: getDeviceLabel(EDeviceTypes.Refrigerator, withIcons),
    },
    {
      value: "Microwave Oven",
      label: getDeviceLabel(EDeviceTypes.MicrowaveOven, withIcons),
    },
    { value: "Bulb", label: getDeviceLabel(EDeviceTypes.Bulb, withIcons) },
    {
      value: "Computer",
      label: getDeviceLabel(EDeviceTypes.Computer, withIcons),
    },
    { value: "Iron", label: getDeviceLabel(EDeviceTypes.Iron, withIcons) },
    {
      value: "Washing Machine",
      label: getDeviceLabel(EDeviceTypes.WashingMachine, withIcons),
    },
    {
      value: "Air Conditioner",
      label: getDeviceLabel(EDeviceTypes.AirConditioner, withIcons),
    },
    { value: "Fan", label: getDeviceLabel(EDeviceTypes.Fan, withIcons) },
  ];
  return deviceTypes;
};

export default getDeviceTypes;
