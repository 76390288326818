import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useLazyProfileQuery,
  useUpdateUserProfileMutation,
} from "../../../../../../redux/api/user/userAPI";
import { selectProfile } from "../../../../../../redux/features/user/user-slice";
import { UserRole } from "../../../../enums/user-roles";
import AppButton from "../../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../ui-elements/input/app-input";
import {
  nicRegex,
  noOnlySpecialCharsNoWhitespaceStartEndRegex,
  noSpecialCharsNoWhitespaceNoNumbersStartEndRegex,
} from "../../../../utils/regex";
import { showErrorMessage, showSuccessMessage } from "../../../../utils/toast";

interface IProps {
  firstName: string;
  lastName: string;
  username: string;
  NIC: string;
  getExtendedProfile: () => void;
}

const defaultFormValue = { firstName: "", lastName: "", NIC: "", username: "" };

const UserProfileData = (props: IProps) => {
  const { firstName, lastName, username, NIC, getExtendedProfile } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const profile = useSelector(selectProfile);
  const [updateUserProfile, { isLoading: isLoadingUpdateUserProfile }] =
    useUpdateUserProfileMutation();

  const [getProfile, { isFetching: isFetchingProfile }] = useLazyProfileQuery();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IProps>({
    defaultValues: { ...defaultFormValue },
  });

  useEffect(() => {
    reset({ ...defaultFormValue });
    if (isEditMode) {
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("username", username);
      setValue("NIC", NIC);
    }
  }, [isEditMode, firstName, lastName, username, NIC, setValue]);

  const onSubmit = (data: IProps) => {
    if (isEditMode) {
      updateUserProfile({
        ...data,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("User Profile Details Updated Successfully");
          return getProfile().unwrap();
        })
        .then(() => {
          getExtendedProfile();
        })
        .catch(() => {
          showErrorMessage("Editing User Profile Details Unsuccessful");
        });
    }
    setIsEditMode(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col
          className={`${
            isEditMode ? `col-12 col-md-6 ` : `col-12 col-md-7 `
          } mb-4`}
        >
          <Row>
            <Col>
              <p className="mb-0 text-light">First&nbsp;Name</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col>
                {isEditMode ? (
                  <AppInput
                    name="firstName"
                    placeholder="First Name"
                    register={register("firstName", {
                      required: "First name is required",
                      minLength: {
                        value: 2,
                        message:
                          "This field must contain at least 2 characters",
                      },

                      maxLength: {
                        value: 50,
                        message:
                          "You have exceeded the maximum number of 50 characters in this field",
                      },
                      pattern: {
                        value: noSpecialCharsNoWhitespaceNoNumbersStartEndRegex,
                        message:
                          "Entered value can't start/end or contain only white spaces and can't contain any special characters and numbers.",
                      },
                    })}
                    errors={errors}
                  />
                ) : (
                  <p className="mb-0 font-weight-500 text-dark">{firstName}</p>
                )}
              </Col>
            </Col>
          </Row>
        </Col>
        <Col
          className={`${
            isEditMode ? `col-12 col-md-6` : `col-12 col-md-5`
          } mb-4`}
        >
          <Row>
            <Col>
              <p className="mb-0 text-light">Last&nbsp;Name</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {isEditMode ? (
                <AppInput
                  name="lastName"
                  placeholder="Last Name"
                  register={register("lastName", {
                    required: "Last name is required",
                    minLength: {
                      value: 2,
                      message: "This field must contain at least 2 characters",
                    },
                    maxLength: {
                      value: 50,
                      message:
                        "You have exceeded the maximum number of 50 characters in this field",
                    },
                    pattern: {
                      value: noSpecialCharsNoWhitespaceNoNumbersStartEndRegex,
                      message:
                        "Entered value can't start/end or contain only white spaces and can't contain any special characters and numbers.",
                    },
                  })}
                  errors={errors}
                />
              ) : (
                <p className="mb-0 font-weight-500 text-dark">{lastName}</p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          className={`${
            isEditMode
              ? `col-12 col-md-6`
              : `${
                  username.length > 17
                    ? `col-lg-7 col-xl-12`
                    : `col-12 col-md-7`
                }`
          } mb-4`}
        >
          <Row>
            <Col>
              <p className="mb-0 text-light">User&nbsp;name</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {isEditMode ? (
                <AppInput
                  name="username"
                  placeholder="Last Name"
                  register={register("username", {
                    required: "Username is required",
                    minLength: {
                      value: 2,
                      message: "This field must contain at least 2 characters",
                    },
                    maxLength: {
                      value: 50,
                      message:
                        "You have exceeded the maximum number of 50 characters in this field",
                    },
                    pattern: {
                      value: noOnlySpecialCharsNoWhitespaceStartEndRegex,
                      message:
                        "Entered value can't start/end or contain only white spaces and can't contain any special characters and numbers.",
                    },
                  })}
                  errors={errors}
                  disabled={true}
                />
              ) : (
                <p className="mb-0 font-weight-500 text-dark">{username}</p>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          className={`${
            isEditMode ? `col-12 col-md-6` : `col-12 col-md-5`
          } mb-2`}
        >
          <Row>
            {isEditMode ? (
              <Col>
                <p className="mb-0 text-light">NIC</p>
              </Col>
            ) : (
              <Col>
                <p className="mb-0 text-light">NIC</p>
              </Col>
            )}
          </Row>
          <Row>
            {isEditMode ? (
              <Col>
                <AppInput
                  name="NIC"
                  placeholder="NIC"
                  register={register("NIC", {
                    required: "NIC is required",
                    pattern: {
                      value: nicRegex,
                      message: "Please enter a valid NIC",
                    },
                  })}
                  errors={errors}
                />
              </Col>
            ) : (
              <Col>
                <p className="mb-0 font-weight-500 text-dark">{NIC}</p>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center justify-content-sm-start">
        {profile &&
          profile.role === UserRole.ROLE_USER &&
          profile.serviceProvider !== "LECO" && (
            <Col className="col-auto mt-3">
              {isEditMode ? (
                <Row>
                  <Col>
                    <AppButton
                      className="me-4"
                      text="Save"
                      size="small"
                      onClick={() => {
                        handleSubmit(onSubmit);
                      }}
                      isLoading={
                        isLoadingUpdateUserProfile || isFetchingProfile
                      }
                    />
                  </Col>
                  <Col>
                    <AppButton
                      text="Cancel"
                      variant="transparent"
                      size="small"
                      onClick={() => {
                        setIsEditMode(false);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <AppButton
                  text="Edit Profile"
                  size="small"
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                />
              )}
            </Col>
          )}
      </Row>
    </form>
  );
};

export default UserProfileData;
