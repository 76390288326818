import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IFilters from "../../../shared/oversight-general-core/interfaces/filters";
import { RootState } from "../../store";

const defaultFilters: IFilters = {
  selectedSpace: { id: "", clusterId: "", name: "All Spaces" },
  searchText: "",
  selectedDeviceType: "",
  selectedGroupBy: "Space",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: { ...defaultFilters },
  reducers: {
    setFilter: (state, action: PayloadAction<IFilters>) => {
      state.selectedSpace = { ...action.payload.selectedSpace };
      state.selectedGroupBy = action.payload.selectedGroupBy;
      state.searchText = action.payload.searchText;
      state.selectedDeviceType = action.payload.selectedDeviceType;
    },
  },
});

export const { setFilter } = filterSlice.actions;

export const selectFilter = (state: RootState) => state.filter;

export default filterSlice.reducer;
