import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useRemoveDeviceMutation } from "../../../../../redux/api/device/deviceAPI";
import { useRemoveSpaceMutation } from "../../../../../redux/api/space/spaceAPI";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import InfoModal from "../../../../../shared/oversight-core/shared-components/info-modal/info-modal";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import IconButton from "../../../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import ListCard from "../../../../../shared/oversight-general-core/components/list-card/list-card";
import AppDropDown from "../../../../../shared/oversight-general-core/ui-elements/app-dropdown/app-drop-down";
import AddUpdateDeviceModal from "../models/add-update-device-modal/add-update-device-model";
import AddUpdateSpaceModal from "../models/add-update-space-modal/add-update-space-modal";
import RemoveSpaceModal from "../models/remove-space-modal/remove-space-modal";
import "./space-container.scss";

interface IProps {
  mainSpace: ISpaceView;
  expandable: boolean;
  isShowIcon: boolean;
  size: number;
  updateCurrentState: () => void;
  collapsed: boolean;
  onSelectSpace: (space: ISpaceView) => void;
}

const SpaceContainer = (props: IProps) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);
  const [showAddUpdateDeviceModal, setShowAddUpdateDeviceModal] =
    useState(false);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [showRemoveSpaceModal, setShowRemoveSpaceModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [
    removeSpace,
    { isSuccess: removeSpaceSuccess, isLoading: isLoadingRemoveSpace },
  ] = useRemoveSpaceMutation();
  const [
    removeDevice,
    { isSuccess: removeDeviceSuccess, isLoading: isLoadingRemoveDevice },
  ] = useRemoveDeviceMutation();
  const [editSpace, setEditSpace] = useState(false);
  const [spaceCreationType, setSpaceCreationType] = useState<
    "space" | "spaceCluster"
  >("space");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (removeSpaceSuccess || removeDeviceSuccess) {
      const message = removeSpaceSuccess
        ? "Space deleted successfully"
        : "Device deleted successfully";
      showSuccessMessage(message);
      props.updateCurrentState();
    }

    const segments = pathname.split("/");
    const lastSegment = segments.pop();

    if (removeSpaceSuccess && props.mainSpace.id === lastSegment) {
      navigate(-1);
    }
  }, [removeSpaceSuccess, removeDeviceSuccess]);

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string
  ) => {
    removeDevice({ clusterId, spaceId, deviceId })
      .unwrap()
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };

  const closeAddUpdateDeviceModal = () => {
    setShowAddUpdateDeviceModal(false);
  };

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  return (
    <div className="space-container py-3 px-2 my-3">
      <Container fluid>
        <div
          onClick={() => props.onSelectSpace(props.mainSpace)}
          style={{ cursor: "pointer" }}
        >
          <Row>
            <Col className="pc-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {props.isShowIcon && (
                  <MaterialIcon icon={"apartment"} className="me-1 d-inline" />
                )}

                <label
                  className="me-2"
                  style={{
                    color: props.isShowIcon ? `#383941` : `#69768B`,
                    fontSize: props.size,
                  }}
                >
                  {props.mainSpace.name}
                </label>
                <AppDropDown
                  items={[
                    {
                      text: "Add Subspace",
                      onClick: () => {
                        setSpaceCreationType("space");
                        setEditSpace(false);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: "Edit Space",
                      onClick: () => {
                        if (props.mainSpace.accountNumber) {
                          setSpaceCreationType("spaceCluster");
                        } else {
                          setSpaceCreationType("space");
                        }
                        setEditSpace(true);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: " Remove Space",
                      onClick: () => {
                        setShowRemoveSpaceModal(true);
                      },
                    },
                  ]}
                />
              </div>
              <div className="d-flex align-items-center">
                <AppButton
                  text="Add Device"
                  size="small"
                  className="me-2 d-none d-sm-block"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setShowAddUpdateDeviceModal(true);
                  }}
                />
                <IconButton
                  icon="add"
                  className="d-block d-sm-none"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setShowAddUpdateDeviceModal(true);
                  }}
                />
                {props.expandable && (
                  <MaterialIcon
                    icon="expand_more"
                    className="cursor-pointer d-arrow"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setCollapsed(!collapsed);
                    }}
                    style={
                      collapsed
                        ? { transform: "rotate(0deg)" }
                        : { transform: "rotate(180deg)" }
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
          <div
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
            style={{ cursor: "default" }}
          >
            <Row className={`${collapsed ? "d-none" : ""} mt-3`}>
              {props.mainSpace.powerConsumers.map((d, index) => (
                <Col key={index} className="py-2 col-12 col-sm-6 col-lg-4">
                  <ListCard
                    deleteDevice={deleteDevice}
                    updateCurrentState={props.updateCurrentState}
                    device={d}
                    mainSpaceClusterId={props.mainSpace.clusterId}
                    mainSpaceId={props.mainSpace.id}
                    deviceStatus={true}
                    isDevicesOnly={false}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <Row className={`${collapsed ? "d-none" : ""}`}>
          <Col>
            {props.mainSpace.childSpaces &&
              props.mainSpace.childSpaces.length > 0 && (
                <div>
                  {props.mainSpace.childSpaces.map((ss, index) => (
                    <SpaceContainer
                      key={index}
                      mainSpace={{
                        ...ss,
                        clusterId: props.mainSpace.clusterId,
                      }}
                      expandable={true}
                      isShowIcon={false}
                      size={14}
                      updateCurrentState={props.updateCurrentState}
                      collapsed={true}
                      onSelectSpace={props.onSelectSpace}
                    />
                  ))}
                </div>
              )}
          </Col>
        </Row>
      </Container>
      <AddUpdateDeviceModal
        show={showAddUpdateDeviceModal}
        onClose={() => setShowAddUpdateDeviceModal(false)}
        onCancel={() => setShowAddUpdateDeviceModal(false)}
        spaceClusterId={props.mainSpace.clusterId}
        spaceId={props.mainSpace.id}
        closeAddUpdateDeviceModal={closeAddUpdateDeviceModal}
        updateCurrentState={props.updateCurrentState}
      />
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceClusterId={props.mainSpace.clusterId}
        parentSpaceId={props.mainSpace.id}
        spaceClusterDetails={props.mainSpace}
        isEditMode={editSpace}
        spaceCreationType={spaceCreationType}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        updateCurrentState={props.updateCurrentState}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
      />
      <RemoveSpaceModal
        show={showRemoveSpaceModal}
        onClose={() => setShowRemoveSpaceModal(false)}
        onCancel={() => setShowRemoveSpaceModal(false)}
        deviceName={props.mainSpace.name}
        onConfirm={() => {
          if (props.mainSpace.childSpaces.length === 0) {
            removeSpace({
              clusterId: props.mainSpace.clusterId,
              spaceId: props.mainSpace.id,
            })
              .unwrap()
              .catch(() => {
                showErrorMessage("Removing Space Unsuccessful");
              });
          } else {
            setShowInfoModal(true);
          }
          setShowRemoveSpaceModal(false);
        }}
      />
      <InfoModal
        show={showInfoModal}
        message="This space contains child spaces hence can&rsquo;t be deleted. Child spaces of this space should be deleted before deleting this space."
        hideCancel={true}
        onConfirm={() => {
          setShowInfoModal(false);
        }}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <SpinnerModal show={isLoadingRemoveSpace || isLoadingRemoveDevice} />
    </div>
  );
};

export default SpaceContainer;
