import { IBreadCrumbChildSpace } from "../oversight-core/ui-elements/breadcrumb/breadcrumb";

const breadcrumbSpacesSearchTree = (
  element: IBreadCrumbChildSpace,
  id: string
): IBreadCrumbChildSpace | null => {
  if (element.id == id) {
    return element;
  } else if (element.scheduledChildSpaces) {
    let i;
    let result: IBreadCrumbChildSpace | null = null;
    for (
      i = 0;
      result == null && i < element.scheduledChildSpaces.length;
      i++
    ) {
      result = breadcrumbSpacesSearchTree(element.scheduledChildSpaces[i], id);
    }
    return result;
  }
  return null;
};

export default breadcrumbSpacesSearchTree;
