import { Col, Row } from "react-bootstrap";
import styles from "./stats-view.module.scss";

interface IProps {
  variant: "bg-icon-2" | "bg-icon-3" | "bg-icon-4" | "bg-icon-5" | "bg-icon-6";
  labelValue: string;
  title: string;
  fontStyle?: "normal" | "bold" | "small";
  fontSize?: number;
}

const StatsViewTypeTwo = (props: IProps) => {
  const { variant, labelValue, title, fontStyle = "bold" } = props;
  return (
    <div className="text-center">
      <Row>
        <Col>
          <div
            className={`${styles.label} ${variant} ${styles[fontStyle]} px-5 px-md-2`}
            style={{ fontSize: props.fontSize }}
          >
            {labelValue}
          </div>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className={`${styles.title}`}>{title}</Col>
      </Row>
    </div>
  );
};

export default StatsViewTypeTwo;
