import styles from "./schedule-select-indicator.module.scss";

interface IProps {
  isWeekdayFilled: boolean;
  isWeekendFilled: boolean;
  powerConsumersAvailable?: boolean;
}

const ScheduleSelectedIndicator = (props: IProps) => {
  const { isWeekdayFilled, isWeekendFilled } = props;
  return props.powerConsumersAvailable ? (
    <>
      <div
        className={`${styles[`day`]} ${styles[`weekday`]} ${
          isWeekdayFilled ? `bg-blue` : `bg-gray-2`
        }`}
      />
      <div
        className={`${styles[`day`]} ${styles[`weekend`]} ${
          isWeekendFilled ? `bg-green` : `bg-gray-2`
        }`}
      />
    </>
  ) : (
    <>
      <div className={`${styles[`day`]} ${styles[`weekday`]} bg-red`} />
      <div className={`${styles[`day`]} ${styles[`weekend`]} bg-red`} />
    </>
  );
};

export default ScheduleSelectedIndicator;
