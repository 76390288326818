import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLazyGetEnergyConsumptionQuery } from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetSpaceClustersWithDepthQuery } from "../../../redux/api/space/spaceAPI";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ConsumptionDataView } from "../../../shared/oversight-core/interfaces/entities/consumption-data-view";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import { IGetSpaceClusters } from "../../../shared/oversight-core/response-dto/get-space-clusters-dto";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import EnergyUsageTab from "./energy-usage-tab/energy-usage-tab";

const defaultFormValues: ConsumptionDataView = {
  months: [],
  predictedConsumption: [],
  actualConsumption: [],
};

const EnergyUsageOld = () => {
  const [spaceClusterList, setSpaceClusterList] = useState<IGetSpaceClusters[]>(
    []
  );
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [selectedClusterId, setSelectedClusterId] = useState("");
  const [hasSchedule, setHasSchedule] = useState(false);
  const [consumptionBills, setConsumptionBills] =
    useState<ConsumptionDataView>(defaultFormValues);
  const [consumptionUnits, setConsumptionUnits] =
    useState<ConsumptionDataView>(defaultFormValues);
  const [energyConsumption, setEnergyConsumption] =
    useState<ConsumptionDataView>(defaultFormValues);

  const [triggerGetSpaceClusters, { isFetching: fetchingGetSpaceClusters }] =
    useLazyGetSpaceClustersWithDepthQuery();

  const [
    triggerGetEnergyConsumption,
    {
      data: getEnergyConsumption,
      isSuccess: isEnergyConsumptionSuccess,
      isFetching: isEnergyConsumptionLoading,
      isError: isEnergyConsumptionError,
    },
  ] = useLazyGetEnergyConsumptionQuery();

  const onChangeClusterId = (clusterId: string) => {
    setSelectedClusterId(clusterId);
  };

  const setHasNoSchedule = (schedule: boolean) => {
    setHasSchedule(schedule);
  };

  useEffect(() => {
    const year = parseInt(format(selectedDate, "yyyy"));
    const viewEnergyAs: EEnergyViewType =
      selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
    const viewConsumptionAs: EConsumptionViewTypes = EConsumptionViewTypes.ALL;
    if (selectedClusterId) {
      triggerGetEnergyConsumption({
        spaceClusterId: selectedClusterId,
        year,
        viewEnergyAs,
        viewConsumptionAs,
      });
    }
  }, [
    triggerGetEnergyConsumption,

    selectedButton,
    selectedDate,
    selectedClusterId,
  ]);

  useEffect(() => {
    if (isEnergyConsumptionSuccess && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.predictedConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.scheduledEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.scheduledEnergySummary
                    ?.energyBill || 0
            );
            return previousValue;
          },
          {
            months: [],
            predictedConsumption: [],
            actualConsumption: [],
          }
        );

      setConsumptionBills(data);
      setConsumptionUnits(data);
    }
  }, [isEnergyConsumptionSuccess, getEnergyConsumption]);

  useEffect(() => {
    if (isEnergyConsumptionError) {
      setConsumptionBills({ ...defaultFormValues });
      setConsumptionUnits({ ...defaultFormValues });
    }
  }, [isEnergyConsumptionError]);

  useEffect(() => {
    if (selectedButton === 1) {
      setEnergyConsumption(consumptionUnits);
    } else {
      setEnergyConsumption(consumptionBills);
    }
  }, [consumptionUnits, consumptionBills, selectedButton]);

  useEffect(() => {
    triggerGetSpaceClusters(0)
      .unwrap()
      .then((response) => {
        setSpaceClusterList(response.spaceClusters);
      });
  }, [triggerGetSpaceClusters]);

  const updateChartActualConsumption = () => {
    const year = parseInt(format(selectedDate, "yyyy"));
    const viewEnergyAs: EEnergyViewType =
      selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
    const viewConsumptionAs: EConsumptionViewTypes = EConsumptionViewTypes.ALL;
    if (selectedClusterId) {
      triggerGetEnergyConsumption({
        spaceClusterId: selectedClusterId,
        year,
        viewEnergyAs,
        viewConsumptionAs,
      });
    }
  };

  const getSelectedDate = (date: Date) => {
    setDate(date);
  };

  const monthsList = energyConsumption.months.map(
    (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
  );

  return (
    <>
      {spaceClusterList.length > 0 ? (
        <div>
          <div className="container-white">
            <EnergyUsageTab
              onChangeClusterId={onChangeClusterId}
              hasNoSchedule={setHasNoSchedule}
              updateChartActualConsumption={updateChartActualConsumption}
              getSelectedDate={getSelectedDate}
            />
          </div>
          {hasSchedule && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  Usage schedule hasn&#39;t been added for this space in{" "}
                  {format(date, "MMMM yyyy")}{" "}
                  <Link
                    to={"/dashboard/schedule"}
                    state={{
                      clusterId: selectedClusterId,
                      date: selectedDate,
                    }}
                  >
                    Add Schedule
                  </Link>
                </Col>
              </Row>
            </div>
          )}
          <div className="container-white mt-4">
            <Row className="align-items-center justify-content-center justify-content-md-end mb-4 g-3">
              <Col className="col-6 col-md-4 col-lg-2 text-center text-sm-end">
                <AppTabs
                  buttons={[
                    {
                      buttonName: "Units",
                      selectedColor: "purpleColor",
                      id: 1,
                    },
                    {
                      buttonName: "Bill",
                      selectedColor: "purpleColor",
                      id: 2,
                    },
                  ]}
                  onChange={(id) => {
                    setSelectedButton(id);
                  }}
                  selectedTabId={selectedButton}
                />
              </Col>
              <Col className="col-6 col-md-8 col-lg-2">
                <AppDatePicker
                  dateFormat="yyyy"
                  selectedDate={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  monthYearPicker={false}
                  yearPicker={true}
                />
              </Col>
            </Row>
            <Row>
              {!isEnergyConsumptionLoading ? (
                <Col>
                  <AreaChart
                    borderColor1="#D84560"
                    backgroundColor1="#CC292917"
                    label1="Actual Usage"
                    yAxesUnit={selectedButton === 1 ? "Units" : "Rs."}
                    labels={monthsList}
                    data1={energyConsumption.actualConsumption}
                  />
                </Col>
              ) : (
                <SpinnerModal show withOverlay={false} />
              )}
            </Row>
          </div>
        </div>
      ) : (
        <div className="container-dash mt-4">
          <Row>
            <Col className="text-center text-light font-size-12">
              You have not created any billing spaces.{" "}
              <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
            </Col>
          </Row>
        </div>
      )}
      <SpinnerModal show={fetchingGetSpaceClusters} />
    </>
  );
};

export default EnergyUsageOld;
