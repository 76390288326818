import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdateMobileNumberMutation } from "../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { AppRoute } from "../../../interfaces/app-routes";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import { mobileNumberRegex } from "../../../utils/regex";
import { showErrorMessage } from "../../../utils/toast";

export interface IChangeMobileNumberFormInput {
  phoneNumber: string;
}

const defaultFormValues: IChangeMobileNumberFormInput = {
  phoneNumber: "",
};

const ChangeMobileNumberForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IChangeMobileNumberFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const [updateMobileNumber, { isLoading: isLoadingUpdateMobileNumber }] =
    useUpdateMobileNumberMutation();

  const onSubmit = (data: IChangeMobileNumberFormInput) => {
    updateMobileNumber({ contactNumber: data.phoneNumber })
      .unwrap()
      .then(() => {
        navigate(AppRoute.VERIFY_MOBILE_NUMBER_OTP, {
          state: {
            number: data.phoneNumber,
            whereTo: "dashboard",
          },
        });
        reset({ ...defaultFormValues });
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
        ) {
          showErrorMessage("Already added mobile number");
          return;
        }
        showErrorMessage(error.message || "Something went wrong");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <AppInput
              name="phoneNumber"
              placeholder="New Phone Number"
              label="New Phone Number"
              type="number"
              register={register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Phone number is invalid",
                },
              })}
              onKeyDown={(event) => {
                ["e", "E", "+", "-", "."].includes(event.key) &&
                  event.preventDefault();
              }}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <AppButton
              text={`Send OTP`}
              size="medium"
              type="submit"
              isLoading={isLoadingUpdateMobileNumber}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ChangeMobileNumberForm;
