import AddSpaceClusterDTO from "../../../shared/oversight-core/dtos/add-space-cluster-dto";
import AddSpaceDTO from "../../../shared/oversight-core/dtos/add-space-dto";
import UpdateSpaceClusterDTO from "../../../shared/oversight-core/dtos/update-space-cluster-dto";
import UpdateSpaceDTO from "../../../shared/oversight-core/dtos/update-space-dto";
import { ISpaceClusterShallowHierarchyView } from "../../../shared/oversight-core/interfaces/space-hierarchy";
import GetSpaceClustersDTO from "../../../shared/oversight-core/response-dto/get-space-clusters-dto";
import ListSpaceClusterResponseDTO from "../../../shared/oversight-core/response-dto/list-space-cluster-response-dto";
import { IScheduledSpaceClusterShallowView } from "../../../shared/oversight-core/response-dto/scheduled-space-sluster-shallow-view-dto";
import SpaceClustersViewResponseDTO from "../../../shared/oversight-core/response-dto/space-clusters-view-response-dto";
import SpaceClusterViewResponseDTO from "../../../shared/oversight-core/response-dto/space-view-response-dto";
import TariffResponseDTO from "../../../shared/oversight-core/response-dto/tariff-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const spaceAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpaces: builder.query<SpaceClustersViewResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `space-cluster/view`,
          method: "GET",
        };
      },
    }),
    getSpace: builder.query<
      SpaceClusterViewResponseDTO,
      { clusterId: string; spaceId: string }
    >({
      transformErrorResponse,
      query({ clusterId, spaceId }) {
        return {
          url: `space-cluster/${clusterId}/sub-root/${spaceId}/view`,
          method: "GET",
        };
      },
    }),
    addSpaceCluster: builder.mutation<
      { spaceClusterId: string; rootSpaceId: string },
      AddSpaceClusterDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: "space-cluster/start",
          method: "POST",
          body: data,
        };
      },
    }),
    addSpace: builder.mutation<
      AddSpaceDTO,
      {
        spaceClusterId: string | undefined;
        parentSpaceId: string | undefined;
        spaceName: string;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/add`,
          method: "POST",
          body: data,
        };
      },
    }),
    editSpaceCluster: builder.mutation<UpdateSpaceDTO, UpdateSpaceClusterDTO>({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/update`,
          method: "PUT",
          body: {
            label: data.label,
            accountNumber: data.accountNumber,
          },
        };
      },
    }),
    editSpace: builder.mutation<
      UpdateSpaceDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        spaceName: string;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/update`,
          method: "PUT",
          body: { spaceName: data.spaceName },
        };
      },
    }),
    removeSpace: builder.mutation<void, { clusterId: string; spaceId: string }>(
      {
        transformErrorResponse,
        query({ clusterId, spaceId }) {
          return {
            url: `space-cluster/${clusterId}/sub-root/${spaceId}/remove`,
            method: "DELETE",
          };
        },
      }
    ),
    getSpaceClusters: builder.query<GetSpaceClustersDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `space-cluster/list`,
          method: "GET",
        };
      },
    }),
    getSpaceClustersWithDepth: builder.query<GetSpaceClustersDTO, number>({
      transformErrorResponse,
      query(depth) {
        return {
          url: `space-cluster/list`,
          method: "GET",
          params: {
            requiredDepth: depth,
          },
        };
      },
    }),
    viewSubSpaceClusterWithSchedule: builder.query<
      { spaceCluster: IScheduledSpaceClusterShallowView },
      {
        clusterId: string;
        rootSpaceId: string;
        year: number;
        month: number;
        requiredDepth?: number;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.clusterId}/sub-root/${data.rootSpaceId}/power-consumer-usage-schedule/list`,
          method: "GET",
          params: {
            year: data.year,
            month: data.month,
            requiredDepth: data.requiredDepth,
          },
        };
      },
    }),
    getSpaceHierarchy: builder.query<
      ISpaceClusterShallowHierarchyView,
      {
        clusterId: string;
        spaceId: string;
      }
    >({
      transformErrorResponse,
      transformResponse({
        spaceCluster,
      }: {
        spaceCluster: ISpaceClusterShallowHierarchyView;
      }) {
        const hierarchy = [...spaceCluster.ancestorSpaces].sort((a, b) => {
          if (a.parentId === null) {
            return -1;
          } else if (b.parentId === null) {
            return 1;
          } else {
            return a.id === b.parentId ? -1 : 1;
          }
        });

        return { ...spaceCluster, ancestorSpaces: hierarchy };
      },
      query(data) {
        return {
          url: `/space-cluster/${data.clusterId}/space/${data.spaceId}/hierarchy`,
          method: "GET",
        };
      },
    }),
    listSpaceCluster: builder.query<
      ListSpaceClusterResponseDTO,
      { clusterId: string }
    >({
      transformErrorResponse,
      query({ clusterId }) {
        return {
          url: `space-cluster/${clusterId}/list`,
          method: "GET",
        };
      },
    }),
    listTariffs: builder.query<TariffResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `tariff/list`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetSpacesQuery,
  useLazyGetSpaceQuery,
  useRemoveSpaceMutation,
  useAddSpaceClusterMutation,
  useAddSpaceMutation,
  useEditSpaceClusterMutation,
  useEditSpaceMutation,
  useGetSpaceClustersQuery,
  useLazyGetSpaceClustersWithDepthQuery,
  useLazyViewSubSpaceClusterWithScheduleQuery,
  useLazyGetSpaceClustersQuery,
  useLazyGetSpaceHierarchyQuery,
  useLazyListSpaceClusterQuery,
  useLazyListTariffsQuery,
} = spaceAPI;
