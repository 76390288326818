import LoginLayout from "../../shared-components/login-layout/login-layout";
import MobileNumberVerificationForm from "./mobile-number-verification-form/mobile-number-verification-form";

const MobileNumberVerification = () => {
  return (
    <LoginLayout
      title="Verify Mobile Number"
      subTitle="Enter new mobile number or just click send OTP button to verify your mobile number."
      showBackButton={true}
    >
      <MobileNumberVerificationForm />
    </LoginLayout>
  );
};

export default MobileNumberVerification;
