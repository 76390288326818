import { Col, Container, Row } from "react-bootstrap";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import AppModal from "../../../../../../shared/oversight-core/ui-elements/app-modal/app-modal";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ViewDeviceModalHeader from "./view-device-modal-header/view-device-modal-header";
import styles from "./view-device-modal.module.scss";

interface IProps {
  device: IPowerConsumerView;
  show: boolean;
  size?: "modal-sm" | "modal-lg" | "modal-md";
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  onClose: () => void;
  icon: MtIcon;
  isSocket?: boolean;
  isAddSocket?: boolean;
  socketName?: string;
  socketId?: string;
}

const ViewDeviceModal = (props: IProps) => {
  return (
    <AppModal show={props.show}>
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col>
            <ViewDeviceModalHeader
              device={props.device}
              icon={props.icon}
              setShowAddUpdateModal={props.setShowAddUpdateModal}
              setShowRemoveDevice={props.setShowRemoveDevice}
            />
          </Col>
          <Col xs="auto" className="p-0">
            <MaterialIcon
              icon="close"
              size={28}
              className="cursor-pointer"
              onClick={props.onClose}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} md={3} className="mt-4 mt-md-0">
            <div className={styles.deviceTitle}>Device Type</div>
            <div className={styles.deviceValue}>{props.device.deviceType}</div>
          </Col>
          <Col xs={12} md={2} className="mt-4 mt-md-0">
            <div className={styles.deviceTitle}>Brand</div>
            <div className={styles.deviceValue}>{props.device.brand}</div>
          </Col>
          <Col xs={12} md={2} className="mt-4 mt-md-0">
            <div className={styles.deviceTitle}>Model</div>
            <div
              className={styles.deviceValue}
              style={{
                wordWrap: "break-word",
              }}
            >
              {props.device.modelNumber}
            </div>
          </Col>
          <Col xs={12} md={5} className="mt-4 mt-md-0">
            <div className={styles.deviceTitle}>Average Energy Usage (W)</div>
            <div className={styles.deviceValue}>
              {props.device.powerUsageInWatt}
            </div>
          </Col>
        </Row>
        {props.isSocket && (
          <>
            <Row className="mt-4">
              <Col>Socket</Col>
            </Row>
            {props.isAddSocket ? (
              <Row
                className={`${styles.socket} align-items-center justify-content-between mt-1 p-2 mx-0`}
              >
                <Col>
                  <Row className="align-items-center">
                    <Col
                      xs={"auto"}
                      className="text-dark font-size-14 font-weight-500"
                    >
                      {props.socketName}
                    </Col>
                    <Col className="text-light font-size-12 font-weight-400">
                      {props.socketId}
                    </Col>
                  </Row>
                </Col>
                <Col xs={"auto"}>
                  <MaterialIcon
                    icon="link_off"
                    color="#2F2A89"
                    className="cursor-pointer"
                  />
                </Col>
              </Row>
            ) : (
              <Row className="align-items-center mt-1 g-2 cursor-pointer">
                <Col xs={"auto"}>
                  <MaterialIcon icon="link" color="#2F2A89" />
                </Col>
                <Col className="text-light font-size-14 font-weight-500">
                  Link Socket
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </AppModal>
  );
};

export default ViewDeviceModal;
