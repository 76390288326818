import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLazyGetEnergyUsageBySpaceIdQuery } from "../../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetSpaceClustersWithDepthQuery } from "../../../../redux/api/space/spaceAPI";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import { Providers } from "../../../../shared/oversight-core/enums/providers";
import IEnergySummaryView from "../../../../shared/oversight-core/interfaces/energy-summary-view";
import { IGetSpaceClusters } from "../../../../shared/oversight-core/response-dto/get-space-clusters-dto";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsView from "../../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import { showErrorMessage } from "../../../../shared/oversight-core/utils/toast";
import AddUpdateBillDetailsModal from "../components/modals/add-update-bill-details-modal/add-update-bill-details-modal";
import styles from "./energy-usage-tab.module.scss";

const defaultFormValues: IEnergySummaryView = {
  consumedEnergySummary: {
    energyInUnits: 0,
    energyBill: 0,
  },
  scheduledEnergySummary: {
    energyInUnits: 0,
    energyBill: 0,
  },
};
interface IProps {
  onChangeClusterId: (clusterId: string) => void;
  hasNoSchedule: (hasSchedule: boolean) => void;
  updateChartActualConsumption: () => void;
  getSelectedDate: (date: Date) => void;
}

const EnergyUsageTab = (props: IProps) => {
  const [showAddUpdateBillDetailsModal, setAddUpdateBillDetailsModal] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hasActualConsumption, setHasActualConsumption] = useState(false);
  const [hasPredictedConsumption, setHasPredictedConsumption] = useState(false);
  const [predictedConsumption, setPredictedConsumption] =
    useState<IEnergySummaryView>(defaultFormValues);
  const [actualConsumption, setActualConsumption] = useState<
    IEnergySummaryView | undefined
  >(defaultFormValues);
  const [selectedCluster, setSelectedCluster] = useState<IGetSpaceClusters>();
  const [spaceClustersList, setSpaceClustersList] = useState<
    IGetSpaceClusters[]
  >([]);

  const [
    triggerGetSpaceClusters,
    {
      isSuccess: getSpaceClusterSuccess,
      data: dataSpaceClusters,
      isLoading: loadingGetSpaceClusters,
    },
  ] = useLazyGetSpaceClustersWithDepthQuery();

  const [
    triggerGetEnergyConsumption,
    {
      data: getEnergyConsumptionData,
      isSuccess: isEnergyConsumptionSuccess,
      isFetching: isEnergyConsumptionLoading,
      isError: isEnergyConsumptionError,
      error,
    },
  ] = useLazyGetEnergyUsageBySpaceIdQuery();

  const profile = useSelector(selectProfile);

  useEffect(() => {
    setActualConsumption(undefined);
    const selectedYear = parseInt(format(selectedDate, "yyyy"));
    const selectedMonth = parseInt(format(selectedDate, "M"));

    if (selectedCluster) {
      triggerGetEnergyConsumption({
        spaceClusterId: selectedCluster.id,
        year: selectedYear,
        month: selectedMonth,
      });
    }
    props.onChangeClusterId(selectedCluster?.id || "");
    props.getSelectedDate(selectedDate);
  }, [triggerGetEnergyConsumption, selectedDate, selectedCluster]);

  useEffect(() => {
    if (isEnergyConsumptionSuccess) {
      if (getEnergyConsumptionData) {
        setPredictedConsumption(
          getEnergyConsumptionData.spaceClusterEnergySummary
        );
        setActualConsumption(
          getEnergyConsumptionData.spaceClusterEnergySummary
        );

        if (
          getEnergyConsumptionData.spaceClusterEnergySummary
            ?.scheduledEnergySummary?.energyInUnits === 0 ||
          !getEnergyConsumptionData.spaceClusterEnergySummary
            ?.scheduledEnergySummary
        ) {
          props.hasNoSchedule(true);
        } else {
          props.hasNoSchedule(false);
        }
      }

      if (
        getEnergyConsumptionData?.spaceClusterEnergySummary
          .scheduledEnergySummary &&
        getEnergyConsumptionData.spaceClusterEnergySummary
          .scheduledEnergySummary.energyInUnits > 0 &&
        getEnergyConsumptionData.spaceClusterEnergySummary
          .scheduledEnergySummary.energyBill > 0
      ) {
        setHasPredictedConsumption(true);
      } else {
        setHasPredictedConsumption(false);
      }

      if (
        getEnergyConsumptionData?.spaceClusterEnergySummary
          .consumedEnergySummary &&
        getEnergyConsumptionData.spaceClusterEnergySummary.consumedEnergySummary
          .energyInUnits > 0 &&
        getEnergyConsumptionData.spaceClusterEnergySummary.consumedEnergySummary
          .energyBill > 0
      ) {
        setHasActualConsumption(true);
      } else {
        setHasActualConsumption(false);
      }
    }

    if (isEnergyConsumptionError && error) {
      const response = error as Response;
      if (response.status === 404) {
        props.hasNoSchedule(true);
      }

      setHasActualConsumption(false);
      setHasPredictedConsumption(false);
      setPredictedConsumption(defaultFormValues);
      setActualConsumption(undefined);
    }
  }, [
    isEnergyConsumptionSuccess,
    getEnergyConsumptionData,
    isEnergyConsumptionError,
    predictedConsumption,
    actualConsumption,
  ]);

  useEffect(() => {
    if (getSpaceClusterSuccess && dataSpaceClusters) {
      setSpaceClustersList(dataSpaceClusters.spaceClusters);
      setSelectedCluster(dataSpaceClusters.spaceClusters[0]);
    }
  }, [getSpaceClusterSuccess, dataSpaceClusters]);

  useEffect(() => {
    triggerGetSpaceClusters(0);
  }, [triggerGetSpaceClusters]);

  const showAddUpdateBillDetailsModalHandler = (show: boolean) => {
    if (show && !selectedCluster?.id) {
      showErrorMessage("Please Select a Space First", "Error");
      return;
    }
    setAddUpdateBillDetailsModal(show);
  };

  const closeAddUpdateBillDetailsModal = () => {
    setAddUpdateBillDetailsModal(false);
  };

  const updateActualConsumption = () => {
    const selectedYear = parseInt(format(selectedDate, "yyyy"));
    const selectedMonth = parseInt(format(selectedDate, "M"));
    if (selectedCluster)
      triggerGetEnergyConsumption({
        spaceClusterId: selectedCluster.id,
        year: selectedYear,
        month: selectedMonth,
      });
    props.updateChartActualConsumption();
  };

  const minDate = moment().utc().subtract(1, "months");
  const maxDate = moment().utc().add(1, "months");

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-12 col-sm-6 col-lg mb-2 mb-md-0">
          <Row>
            <Col className="col-12 col-xl mb-2 mb-xl-0">
              <AppSelect
                selectedValue={{
                  value: selectedCluster?.id || "",
                  label: selectedCluster?.rootSpace.name || "",
                  data: selectedCluster,
                }}
                placeholder="Select Space"
                options={
                  spaceClustersList
                    ? spaceClustersList.map((spaceCluster) => {
                        return {
                          value: spaceCluster.id,
                          label: spaceCluster.rootSpace.name,
                          data: spaceCluster,
                        };
                      })
                    : []
                }
                onChangeOption={(selectedOption) => {
                  setSelectedCluster(selectedOption.data as IGetSpaceClusters);
                }}
              />
            </Col>
            <Col className="col-12 col-xl">
              <AppDatePicker
                selectedDate={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                monthYearPicker={true}
                yearPicker={false}
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-sm-6 col-lg mb-2 mb-md-0">
          {!isEnergyConsumptionLoading ? (
            <Row className="align-items-center justify-content-center justify-content-md-end">
              {hasPredictedConsumption && (
                <Col className="col-12 col-xl-5 mb-2 mb-xl-0">
                  <StatsView
                    labelValue={`${predictedConsumption?.scheduledEnergySummary.energyInUnits.toFixed(
                      2
                    )} Units | Rs. ${predictedConsumption?.scheduledEnergySummary.energyBill.toFixed(
                      2
                    )}`}
                    title="Usage Prediction"
                    variant="bg-icon-5"
                    fontStyle="small"
                    columnClass="col-3"
                    fontSize={10}
                  />
                </Col>
              )}
              {hasActualConsumption ? (
                <Col className="col-12 col-xl-5 mb-2 mb-xl-0">
                  <StatsView
                    labelValue={`${
                      actualConsumption?.consumedEnergySummary.energyInUnits
                    } Units | Rs. ${actualConsumption?.consumedEnergySummary.energyBill.toFixed(
                      2
                    )}`}
                    title="Actual Usage"
                    variant="bg-icon-6"
                    fontStyle="small"
                    columnClass="col-3"
                    fontSize={10}
                  />
                </Col>
              ) : (
                profile &&
                profile.serviceProvider === Providers.LECO && (
                  <Col className="col-12 col-xl-6 mb-2 mb-xl-0">
                    <div className={`${styles.assumption} p-2`}>
                      <Row className="align-items-center">
                        <Col className="col-auto pe-1">
                          <MaterialIcon icon="info" color="#549EC7" />
                        </Col>
                        <Col className="font-size-12 text-muted font-weight-500">
                          Actual usage of this month will be added here soon
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              )}
              {profile && profile.serviceProvider === Providers.CEB && (
                <Col className="col-7 col-sm-12 col-xl-auto text-center">
                  {hasActualConsumption ? (
                    <>
                      <AppButton
                        text="Edit"
                        iconName="drive_file_rename_outline"
                        iconOnly
                        className="d-none d-xl-block"
                        size="medium"
                        onClick={() =>
                          showAddUpdateBillDetailsModalHandler(true)
                        }
                      />
                      <AppButton
                        text="Edit"
                        iconName="drive_file_rename_outline"
                        className="d-xl-none"
                        size="medium"
                        onClick={() =>
                          showAddUpdateBillDetailsModalHandler(true)
                        }
                      />
                    </>
                  ) : (
                    <AppButton
                      text="Input Usage"
                      size="medium"
                      onClick={() => showAddUpdateBillDetailsModalHandler(true)}
                    />
                  )}
                </Col>
              )}
            </Row>
          ) : (
            <SpinnerModal show withOverlay={false} size="sm" />
          )}
        </Col>
      </Row>
      <AddUpdateBillDetailsModal
        closeAddUpdateBillDetailsDataModal={closeAddUpdateBillDetailsModal}
        onClose={() => showAddUpdateBillDetailsModalHandler(false)}
        onCancel={() => showAddUpdateBillDetailsModalHandler(false)}
        show={showAddUpdateBillDetailsModal}
        month={selectedDate}
        year={format(selectedDate, "yyyy")}
        rootSpaceId={selectedCluster?.id || ""}
        rootSpaceName={selectedCluster?.rootSpace.name || ""}
        actualConsumption={actualConsumption}
        updateActualConsumption={updateActualConsumption}
        isEditMode={
          actualConsumption?.consumedEnergySummary?.energyBill ? true : false
        }
        accountNumber={selectedCluster?.accountNumber || ""}
      />
      <SpinnerModal show={loadingGetSpaceClusters} />
    </>
  );
};

export default EnergyUsageTab;
