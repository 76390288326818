import { useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { selectProfile } from "../../../redux/features/user/user-slice";

import { consumerPrivateRoutes } from "../../../screens/routes";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import MobileNavBar from "../../oversight-core/shared-components/mobile-nav-bar/mobile-nav-bar";
import SideBar from "../../oversight-core/shared-components/sidebar/side-bar";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import ScrollToTop from "../../oversight-core/ui-elements/scroll-to-top/ScrollToTop";
import ErrorBoundary from "../../oversight-core/utils/ErrorBoundary";
import styles from "./dashboard-layout.module.scss";

const DashboardLayout = () => {
  const profile = useSelector(selectProfile);
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const getCurrentPageInfo = (path: string) => {
    const page = consumerPrivateRoutes
      .filter((route) => route.root)[0]
      ?.subRoutes?.find((pi) => pi.path === path);

    if (page) {
      return page;
    }

    if (path.startsWith("/dashboard/space-clusters")) {
      const dashboard = consumerPrivateRoutes.find(
        (route) => route.name === "Dashboard"
      );

      if (dashboard && dashboard.subRoutes) {
        const page = dashboard.subRoutes.find((route) => {
          return route.path.startsWith("/dashboard/space-clusters/");
        });

        if (page) {
          return page;
        }
      }
    }
  };

  const closeOpen = () => {
    setOpen((ps) => !ps);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`${styles["main-container"]} vh-100`}>
      <div
        className={`${styles["navbar-container"]} vh-100 d-none d-md-block ${
          collapsed
            ? styles["navbar-container-collapsed"]
            : styles["navbar-container-notCollapsed"]
        }`}
      >
        <SideBar collapsed={collapsed} routes={consumerPrivateRoutes} />
      </div>
      <div
        className={`${styles["main-content"]} ${
          collapsed
            ? styles["main-content-collapsed"]
            : styles["main-content-notCollapsed"]
        }`}
      >
        <div
          className={`ps-lg-2 py-3 ${styles["header-container"]} ${
            collapsed
              ? styles["main-content-collapsed"]
              : styles["main-content-notCollapsed"]
          }`}
        >
          <Container fluid className={styles["header"]}>
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col
                    xs="auto"
                    className="d-flex align-item-center pe-0 d-none d-lg-block"
                  >
                    <MaterialIcon
                      icon="menu"
                      onClick={handleCollapse}
                      className={`me-2 ${styles["collapse-icon"]}`}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                  <Col xs="auto" className="pe-4 pe-lg-0">
                    <Row className="align-item-center gap-2">
                      {getCurrentPageInfo(pathname)?.name === "Dashboard" ? (
                        <Col
                          xs="auto"
                          className={`${styles["title-long"]} p-0`}
                        >
                          <span className="d-none d-md-inline-block">
                            Hello,{" "}
                          </span>
                          Welcome to Oversight
                        </Col>
                      ) : (
                        <Col xs="auto" className={`${styles["title"]} p-0`}>
                          <Row className="align-items-center g-2">
                            <Col className="col-auto pe-0">
                              {getCurrentPageInfo(pathname)?.name}
                            </Col>
                            {getCurrentPageInfo(pathname)?.name ===
                              "Usage > Devices" && (
                              <Col>
                                <div
                                  className={`bg-red ${styles.circle}`}
                                ></div>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      )}

                      <Col
                        xs="auto"
                        className="p-0 d-flex d-lg-none align-item-center"
                      >
                        <MaterialIcon
                          icon="menu"
                          onClick={() => {
                            setOpen((ps) => !ps);
                          }}
                          className={`me-2 d-md-none ${styles["open-icon"]}`}
                          style={{ cursor: "pointer" }}
                        />
                        <MaterialIcon
                          icon="menu"
                          onClick={handleCollapse}
                          className={`me-2 ${styles["collapse-icon"]} d-none d-md-block`}
                          style={{ cursor: "pointer" }}
                        />
                        <MobileNavBar
                          isOpen={open}
                          closeOpen={closeOpen}
                          routes={consumerPrivateRoutes}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="order-first order-lg-last">
                <Container>
                  <Row className="align-items-center justify-content-center justify-content-sm-end pe-4">
                    <Col xs="auto" className="p-0 order-2 order-lg-1">
                      <MaterialIcon
                        icon="notifications"
                        className="me-2"
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                    <Col xs="auto" className="px-2 order-1 order-lg-2">
                      <Link to={AppRoute.USER_PROFILE}>
                        <Avatar
                          name={`${profile?.firstName} ${profile?.lastName}`}
                          size="40"
                          round={true}
                        />
                      </Link>
                    </Col>
                    <Col xs="auto" className="p-0 d-none d-lg-block order-lg-3">
                      <div className="text-dark-2 font-weight-600">
                        {profile?.firstName} {profile?.lastName}
                      </div>
                      <div className="text-gray font-size-14 font-weight-400">
                        {profile?.email}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`ps-lg-2 pb-4 ${styles["content-container"]}`}>
          <Container fluid>
            <ScrollToTop />
            <Row>
              <Col xs={12} className={styles["content"]}>
                <ErrorBoundary key={pathname}>
                  <Outlet />
                </ErrorBoundary>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
