import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  useLazyUpdateScheduleQuery,
  useLazyViewScheduleQuery,
  useLazyViewScheduleWithAverageUnitsQuery,
} from "../../../redux/api/schedule/scheduleAPI";
import {
  useLazyGetSpaceClustersWithDepthQuery,
  useLazyViewSubSpaceClusterWithScheduleQuery,
} from "../../../redux/api/space/spaceAPI";
import ScheduleSaveUsageModal from "../../../shared/components/schedule-save-usage-model/schedule-save-model";
import ScheduleSubSpaceContainer from "../../../shared/components/schedule-sub-space-container/schedule-sub-space-container";
import { IPowerConsumerUsageSchedule } from "../../../shared/oversight-core/dtos/add-schedule-dto";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IEnergyView } from "../../../shared/oversight-core/interfaces/entities/energy-view";
import { IPowerConsumerUsageScheduleView } from "../../../shared/oversight-core/interfaces/power-consumer-usage-schedule-view";
import { IScheduledSpaceClusterAverageWeeklyEnergy } from "../../../shared/oversight-core/interfaces/scheduled-space-cluster-average-weekly-energy";
import { IGetSpaceClusters } from "../../../shared/oversight-core/response-dto/get-space-clusters-dto";
import { IEstimatedConsumption } from "../../../shared/oversight-core/response-dto/view-schedule-dto";
import { ViewScheduleWithAverageUnitsBySpaceClusterIdentity } from "../../../shared/oversight-core/response-dto/view-schedule-with-average-units-by-space-cluster-identity";
import ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO from "../../../shared/oversight-core/response-dto/view-scheduled-space-cluster-with-scheduled-space-response-dto";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSave from "../../../shared/oversight-core/ui-elements/app-saving/app-save";
import AppSelect from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import { ITableColumn } from "../../../shared/oversight-core/ui-elements/app-table/models";
import AppTable from "../../../shared/oversight-core/ui-elements/app-table/table";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AverageUsageUnits from "../../../shared/oversight-core/ui-elements/average-usage-units/average-usage-units";
import Breadcrumb, {
  IBreadCrumbChildSpace,
} from "../../../shared/oversight-core/ui-elements/breadcrumb/breadcrumb";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsView from "../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import StatsViewTypeTwo from "../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import Helper from "../../../shared/oversight-core/utils/helpers";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import breadcrumbSpacesSearchTree from "../../../shared/utils/breadcrumbSpacesSearchTree";
import transformScheduleDTO from "../../../shared/utils/transform-schedule-dto";
import styles from "./schedule.module.scss";

const Schedule = () => {
  const [breadCrumbPath, setBreadCrumbPath] = useState<IBreadCrumbChildSpace[]>(
    []
  );
  const [selectedSpaceSelect, setSelectedSpaceSelect] =
    useState<IGetSpaceClusters>();
  const [spaceClustersList, setSpaceClustersList] = useState<
    IGetSpaceClusters[]
  >([]);
  const [showSaveUsageModal, setShowSaveUsageModal] = useState(false);
  const [changeCount, setChangeCount] = useState(0);
  const [subSpaces, setSubSpaces] = useState<IBreadCrumbChildSpace[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState(1);
  const [mode, setMode] = useState<"View" | "Edit">("View");
  const [estimatedConsumption, setEstimatedConsumption] =
    useState<IEstimatedConsumption>({
      estimatedUnit: 0,
      percentageFromTotal: 0,
    });
  const [noDevices, setNoDevices] = useState(false);
  const [
    scheduledSpaceClusterAverageWeeklyEnergy,
    setScheduledSpaceClusterAverageWeeklyEnergy,
  ] = useState<IScheduledSpaceClusterAverageWeeklyEnergy | undefined>(
    undefined
  );

  const [tableDataWeekdays, setTableDataWeekdays] = useState<ITableColumn[]>(
    []
  );
  const [tableDataWeekends, setTableDataWeekends] = useState<ITableColumn[]>(
    []
  );
  const [selectedSpaceDetails, setSelectedSpaceDetails] = useState({
    spaceClusterId: "",
    spaceId: "",
  });

  const [firstTime, setFirstTime] = useState(false);
  const [isGridUpdated, setIsGridUpdated] = useState(false);

  const [
    spaceClusterEstimatedConsumption,
    setSpaceClusterEstimatedConsumption,
  ] = useState<IEnergyView>({ energyBill: 0, energyInUnits: 0 });

  const minDate = moment().utc().subtract(1, "months");
  const maxDate = moment().utc().add(1, "months");

  const { state: routerState } = useLocation();

  const [triggerGetSchedule, { isFetching: isFetchingSchedule }] =
    useLazyViewScheduleQuery();
  const [triggerUpdateSchedule, { isFetching: loadingUpdate }] =
    useLazyUpdateScheduleQuery();
  const [
    triggerGetSpaceClusters,
    {
      isSuccess: getSpaceClusterSuccess,
      data: dataSpaceClusters,
      isFetching: fetchingGetSpaceClusters,
    },
  ] = useLazyGetSpaceClustersWithDepthQuery();

  const [
    getScheduleWithAverageUnits,
    { isFetching: isFetchingScheduleWithAverageUnits },
  ] = useLazyViewScheduleWithAverageUnitsQuery();

  const [
    triggerGetSpaceClusterWithSchedule,
    { isFetching: loadingGetSpaceClusterWithSchedule },
  ] = useLazyViewSubSpaceClusterWithScheduleQuery();

  useEffect(() => {
    if (getSpaceClusterSuccess && dataSpaceClusters) {
      setSpaceClustersList(dataSpaceClusters.spaceClusters);
      setSelectedSpaceSelect((ps) => {
        if (ps) {
          return ps;
        } else {
          return dataSpaceClusters.spaceClusters[0];
        }
      });

      if (routerState) {
        const state = routerState as { clusterId: string; date: Date };

        setSelectedSpaceSelect(() => {
          const index = dataSpaceClusters.spaceClusters.findIndex(
            (sc) => sc.id === state.clusterId
          );
          if (index !== -1) {
            return dataSpaceClusters.spaceClusters[index];
          }
          return dataSpaceClusters.spaceClusters[0];
        });
        setSelectedDate(state.date);
      } else {
        setSelectedSpaceSelect((ps) => {
          if (ps) {
            return ps;
          } else {
            return dataSpaceClusters.spaceClusters[0];
          }
        });
      }
    }
  }, [getSpaceClusterSuccess, dataSpaceClusters, routerState]);

  useEffect(() => {
    triggerGetSpaceClusters(0);
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    if (selectedSpaceSelect) {
      triggerGetSpaceClusterWithSchedule({
        clusterId: selectedSpaceSelect.id || "",
        rootSpaceId: selectedSpaceSelect.rootSpace.id || "",
        year: +moment(selectedDate).format("yy"),
        month: +moment(selectedDate).format("MM"),
      })
        .unwrap()
        .then(({ spaceCluster }) => {
          setBreadCrumbPath([
            {
              parentSpaceId: spaceCluster.id || "",
              id: spaceCluster.scheduledRootSpace.id || "",
              name: spaceCluster.scheduledRootSpace.name || "",
              scheduledChildSpaces:
                spaceCluster.scheduledRootSpace.scheduledChildSpaces || [],
              weekdayScheduled:
                spaceCluster.scheduledRootSpace.weekdayScheduled || false,
              weekendScheduled:
                spaceCluster.scheduledRootSpace.weekendScheduled || false,
              powerConsumersAvailable:
                spaceCluster.scheduledRootSpace.powerConsumersAvailable ||
                false,
            },
          ]);
          setSubSpaces(
            spaceCluster.scheduledRootSpace.scheduledChildSpaces || []
          );
          receiveSpaceSelectorData(
            spaceCluster.id || "",
            spaceCluster.scheduledRootSpace.id || "",
            true
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedSpaceSelect]);

  useEffect(() => {
    if (selectedSpaceSelect) {
      getScheduleWithAverageUnits({
        spaceClusterId: selectedSpaceSelect.id || "",
        year: +moment(selectedDate).format("yy"),
        month: +moment(selectedDate).format("MM"),
      })
        .unwrap()
        .then(
          (response: ViewScheduleWithAverageUnitsBySpaceClusterIdentity) => {
            setScheduledSpaceClusterAverageWeeklyEnergy(
              response.scheduledSpaceClusterAverageWeeklyEnergy
            );

            setSpaceClusterEstimatedConsumption(
              response.scheduledSpaceClusterEnergy
            );
          }
        )
        .catch(() => {
          setSpaceClusterEstimatedConsumption({
            energyBill: 0,
            energyInUnits: 0,
          });
          setScheduledSpaceClusterAverageWeeklyEnergy(undefined);
        });
    }
  }, [selectedSpaceSelect, isGridUpdated, selectedDate]);

  const loadSelectedClusterData = (loadGridData: boolean, date?: Date) => {
    if (selectedSpaceSelect) {
      triggerGetSpaceClusterWithSchedule({
        clusterId: selectedSpaceSelect.id || "",
        rootSpaceId: selectedSpaceSelect.rootSpace.id || "",
        year: +(date
          ? moment(date).format("yy")
          : moment(selectedDate).format("yy")),
        month: +(date
          ? moment(date).format("MM")
          : moment(selectedDate).format("MM")),
      })
        .unwrap()
        .then(({ spaceCluster }) => {
          setBreadCrumbPath((ps) => {
            const path: IBreadCrumbChildSpace = {
              parentSpaceId: spaceCluster.id || "",
              id: spaceCluster.scheduledRootSpace.id || "",
              name: spaceCluster.scheduledRootSpace.name || "",
              scheduledChildSpaces:
                spaceCluster.scheduledRootSpace.scheduledChildSpaces || [],
              weekdayScheduled:
                spaceCluster.scheduledRootSpace.weekdayScheduled || false,
              weekendScheduled:
                spaceCluster.scheduledRootSpace.weekendScheduled || false,
              powerConsumersAvailable:
                spaceCluster.scheduledRootSpace.powerConsumersAvailable ||
                false,
            };

            if (ps && ps.length === 0) {
              setSubSpaces(
                spaceCluster.scheduledRootSpace.scheduledChildSpaces || []
              );

              return [path];
            } else {
              ps = ps.map((item: IBreadCrumbChildSpace) => {
                const updated = breadcrumbSpacesSearchTree(path, item.id);
                return updated ? updated : item;
              });
              setSubSpaces(ps[ps.length - 1].scheduledChildSpaces || []);
              receiveSpaceSelectorData(
                path.parentSpaceId || "",
                ps[ps.length - 1].id || "",
                loadGridData,
                date
              );
              return [...ps];
            }
          });
        });
    }
  };

  const updateSpaces = (updateParams: {
    spaceName?: string;
    parentId?: string;
    id?: string;
    childSpaces?: IBreadCrumbChildSpace[];
    path: IBreadCrumbChildSpace[];
  }) => {
    setBreadCrumbPath(updateParams.path);
    setSubSpaces(updateParams.childSpaces || []);
    if (updateParams.parentId && updateParams.id) {
      receiveSpaceSelectorData(
        selectedSpaceSelect?.id || "",
        updateParams.id,
        true
      );
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleGridTabChange = (id: number) => {
    setSelectedTab(id);
  };

  const receiveSpaceSelectorData = (
    spaceClusterId: string,
    spaceId: string,
    loadGridData: boolean,
    date?: Date
  ) => {
    setSelectedSpaceDetails({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
    });

    triggerGetSchedule({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
      year: +moment(date ? date : selectedDate).format("yy"),
      month: +moment(date ? date : selectedDate).format("MM"),
    })
      .unwrap()
      .then(
        (
          scheduleSpaceData: ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO
        ) => {
          setEstimatedConsumption({
            estimatedUnit:
              scheduleSpaceData.scheduledSubRootSpaceEnergy.energyInUnits,
            percentageFromTotal:
              scheduleSpaceData.scheduledSubRootSpaceEnergyAsPercentageToSpaceCluster,
          });

          if (loadGridData) {
            const dataWD: ITableColumn[] = [];
            const dataWE: ITableColumn[] = [];

            scheduleSpaceData.scheduledSubSpaceCluster.scheduledRootSpace.powerConsumerUsageSchedule.map(
              (schedule: IPowerConsumerUsageScheduleView) => {
                dataWD.push({
                  id: schedule.powerConsumerView.id,
                  title: schedule.powerConsumerView.name,
                  timeSlots: schedule.weeklySchedule?.weekdaySchedulePerDay
                    ? schedule.weeklySchedule.weekdaySchedulePerDay.map(
                        (slot) => ({
                          fromInMinutes: slot.from.hours * 60,
                          toInMinutes: slot.to.hours * 60,
                        })
                      )
                    : [],
                });

                dataWE.push({
                  id: schedule.powerConsumerView.id,
                  title: schedule.powerConsumerView.name,
                  timeSlots: schedule?.weeklySchedule?.weekendSchedulePerDay
                    ? schedule.weeklySchedule.weekendSchedulePerDay.map(
                        (slot) => ({
                          fromInMinutes: slot.from.hours * 60,
                          toInMinutes: slot.to.hours * 60,
                        })
                      )
                    : [],
                });
              }
            );

            setTableDataWeekdays(dataWD);
            setTableDataWeekends(dataWE);

            if (dataWD.length <= 0 && dataWE.length <= 0) {
              setNoDevices(true);
            } else {
              setNoDevices(false);
              if (
                dataWD.filter(
                  (wd: ITableColumn) => (wd.timeSlots?.length || 0) === 0
                ).length === dataWD.length ||
                dataWE.filter(
                  (we: ITableColumn) => (we.timeSlots?.length || 0) === 0
                ).length === dataWE.length
              ) {
                if (
                  dataWD.filter(
                    (wd: ITableColumn) => (wd.timeSlots?.length || 0) === 0
                  ).length === dataWD.length
                ) {
                  setSelectedTab(1);
                } else {
                  setSelectedTab(2);
                }
                setMode("Edit");
              } else {
                setMode("View");
              }
            }

            setFirstTime(
              dataWD
                .map((a: ITableColumn) => {
                  return (a.timeSlots?.length || 0) <= 0;
                })
                .every((a: boolean) => a === true) &&
                dataWE
                  .map((b: ITableColumn) => {
                    return (b.timeSlots?.length || 0) <= 0;
                  })
                  .every((b: boolean) => b === true)
            );
          }
        }
      );
  };

  useEffect(() => {
    if (changeCount > 5) {
      const data: IPowerConsumerUsageSchedule[] = transformScheduleDTO(
        tableDataWeekdays,
        tableDataWeekends
      );

      if (
        selectedSpaceDetails.spaceClusterId &&
        selectedSpaceDetails.spaceId &&
        selectedDate &&
        data &&
        data.length > 0
      ) {
        triggerUpdateSchedule({
          spaceClusterId: selectedSpaceDetails.spaceClusterId,
          spaceId: selectedSpaceDetails.spaceId,
          year: +moment(selectedDate).format("yy"),
          month: +moment(selectedDate).format("MM"),
          data: { usageSchedule: data },
        })
          .unwrap()
          .then(() => {
            receiveSpaceSelectorData(
              selectedSpaceDetails.spaceClusterId,
              selectedSpaceDetails.spaceId,
              false
            );
            setChangeCount(0);
          });
      }
    }
  }, [changeCount]);

  const saveData = () => {
    const data: IPowerConsumerUsageSchedule[] = transformScheduleDTO(
      tableDataWeekdays,
      tableDataWeekends
    );

    if (
      selectedSpaceDetails.spaceClusterId &&
      selectedSpaceDetails.spaceId &&
      selectedDate &&
      data &&
      data.length > 0
    ) {
      triggerUpdateSchedule({
        spaceClusterId: selectedSpaceDetails.spaceClusterId,
        spaceId: selectedSpaceDetails.spaceId,
        year: +moment(selectedDate).format("yy"),
        month: +moment(selectedDate).format("MM"),
        data: { usageSchedule: data },
      })
        .unwrap()
        .then(() => {
          setChangeCount(0);
          setMode("View");
          loadSelectedClusterData(false);
          if (firstTime) {
            showSuccessMessage("Schedule added successful");
            setFirstTime(false);
          } else {
            showSuccessMessage("Schedule update was successful");
          }
          setIsGridUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Error updating schedule");
        });
    }
  };

  const checkSubSpacesNotHaveSchedule = (
    space: IBreadCrumbChildSpace
  ): boolean => {
    if (
      space.powerConsumersAvailable &&
      (!space.weekdayScheduled || !space.weekendScheduled)
    ) {
      return true;
    } else {
      const childSpaceResults = [];
      for (const cs of space?.scheduledChildSpaces || []) {
        childSpaceResults.push(checkSubSpacesNotHaveSchedule(cs));
      }

      let status = false;

      for (const result of childSpaceResults) {
        if (result) {
          status = result;
          break;
        }
      }

      return status;
    }
  };

  return (
    <>
      {spaceClustersList && spaceClustersList.length > 0 ? (
        <div>
          <div className="container-white">
            <Row className={`align-items-center justify-content-between`}>
              <Col lg={5} md={12}>
                <Row
                  className={`justify-content-center justify-content-xl-start align-items-center`}
                >
                  <Col md={12} lg={7}>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <label
                          className={`mb-0 me-2 jakarta font-weight-600 font-size-10 text-dark`}
                        >
                          Billing <br /> Space
                        </label>
                      </Col>
                      <Col xs="auto" style={{ flex: 1 }} className="p-0">
                        <AppSelect
                          placeholder="Select Space"
                          selectedValue={{
                            value: selectedSpaceSelect?.id || "",
                            label: selectedSpaceSelect?.rootSpace.name || "",
                            data: selectedSpaceSelect,
                          }}
                          options={
                            spaceClustersList
                              ? spaceClustersList.map((spaceCluster) => {
                                  return {
                                    value: spaceCluster.id,
                                    label: spaceCluster.rootSpace.name,
                                    data: spaceCluster,
                                  };
                                })
                              : []
                          }
                          onChangeOption={(selectedOption) => {
                            setSelectedSpaceSelect(
                              selectedOption.data as IGetSpaceClusters
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={12}
                    lg={5}
                    className={`${
                      styles[`date-picker`]
                    } mt-2 mt-lg-0 px-0 px-lg-2`}
                  >
                    <AppDatePicker
                      monthYearPicker
                      onChange={(date) => {
                        handleDateChange(date);
                        loadSelectedClusterData(true, date);
                      }}
                      selectedDate={selectedDate}
                      minDate={minDate.toDate()}
                      maxDate={maxDate.toDate()}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={7}>
                {!isFetchingScheduleWithAverageUnits ? (
                  <Row className="align-items-center justify-content-center justify-content-lg-end mt-2 mt-xl-0">
                    {scheduledSpaceClusterAverageWeeklyEnergy &&
                    (scheduledSpaceClusterAverageWeeklyEnergy.weekdayDailyEnergy
                      .energyInUnits > 0 ||
                      scheduledSpaceClusterAverageWeeklyEnergy
                        .weekendDailyEnergy.energyInUnits > 0 ||
                      scheduledSpaceClusterAverageWeeklyEnergy.weeklyEnergy
                        .energyInUnits > 0) ? (
                      <Col md={12} lg={"auto"}>
                        <AverageUsageUnits
                          variant="bg-icon-2"
                          title="Average Usage Units"
                          labelValue={{
                            weekDay:
                              Helper.roundTo2(
                                scheduledSpaceClusterAverageWeeklyEnergy
                                  ?.weekdayDailyEnergy.energyInUnits
                              ) + "",
                            weekEnd:
                              Helper.roundTo2(
                                scheduledSpaceClusterAverageWeeklyEnergy
                                  ?.weekendDailyEnergy.energyInUnits
                              ) + "",
                            weekly:
                              Helper.roundTo2(
                                scheduledSpaceClusterAverageWeeklyEnergy
                                  ?.weeklyEnergy.energyInUnits
                              ) + "",
                          }}
                          titleStyle="jakarta font-weight-500 text-dark"
                        />
                      </Col>
                    ) : (
                      <></>
                    )}

                    {spaceClusterEstimatedConsumption.energyInUnits > 0 ||
                    spaceClusterEstimatedConsumption.energyBill > 0 ? (
                      <>
                        <Col md={6} lg="auto" className="ps-0 pe-0  ps-lg-2">
                          <StatsViewTypeTwo
                            variant="bg-icon-2"
                            labelValue={
                              Helper.roundTo2(
                                spaceClusterEstimatedConsumption.energyInUnits
                              ) + ""
                            }
                            title="Estimated Units"
                          />
                        </Col>
                        <Col
                          md={6}
                          lg="auto"
                          className="pe-0 pe-lg-2 ps-0 ps-md-3"
                        >
                          <StatsViewTypeTwo
                            variant="bg-icon-2"
                            labelValue={
                              "Rs." +
                              Helper.roundTo2(
                                spaceClusterEstimatedConsumption?.energyBill
                              ) +
                              ""
                            }
                            title="Estimated Bill"
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                ) : (
                  <SpinnerModal show withOverlay={false} size="sm" />
                )}
              </Col>
            </Row>
          </div>

          <div className="container-white mt-4">
            <Row>
              <Col className="pe-2">
                <Breadcrumb
                  path={breadCrumbPath}
                  showLastPath={true}
                  updateSpaces={updateSpaces}
                  sendSpaceName={false}
                  showIndicators={true}
                />
              </Col>
            </Row>
            <Row>
              <Col className="my-2">
                <ScheduleSubSpaceContainer
                  subSpaces={[...subSpaces]}
                  updateSpaces={updateSpaces}
                  currentBreadCrumbPath={breadCrumbPath}
                  clusterId={selectedSpaceDetails.spaceClusterId}
                />
              </Col>
            </Row>
            <Row>
              <Col className="py-2">
                <Row
                  className={`justify-content-between ${styles[`weekday-row`]}`}
                >
                  <Col className="col-12">
                    <Row>
                      <Col className="ps-0 ps-sm-2 mb-3 mb-lg-0 col-6 col-md-auto order-1">
                        <AppTabs
                          buttons={[
                            {
                              buttonName: "Weekdays",
                              selectedColor: "lightBlue",
                              id: 1,
                            },
                            {
                              buttonName: "Weekends",
                              selectedColor: "lightGreen",
                              id: 2,
                            },
                          ]}
                          onChange={handleGridTabChange}
                          selectedTabId={selectedTab}
                        />
                      </Col>
                      <Col className="col-12 col-md ps-0 ps-sm-1 mt-md-2 d-flex align-items-center justify-content-center justify-content-md-start order-3 order-md-2">
                        {loadingUpdate ? (
                          <AppSave />
                        ) : (
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#aaa",
                            }}
                          >
                            {changeCount > 0 ? "Change Available" : ""}
                          </div>
                        )}
                      </Col>

                      {mode === "Edit" &&
                        (!isFetchingSchedule ? (
                          !noDevices && (
                            <Col
                              className={`col-6 col-md-auto d-flex justify-content-end ps-0 order-2 order-md-3`}
                            >
                              <AppButton
                                text={firstTime ? "Save" : "Update"}
                                variant="blue"
                                size="medium"
                                style={{ width: "100px" }}
                                onClick={() => {
                                  if (changeCount > 0) {
                                    const allWDFilled = tableDataWeekdays
                                      .map((d) => {
                                        return d.timeSlots
                                          ? d.timeSlots.length === 0
                                          : [].length === 0;
                                      })
                                      .every((d) => d);
                                    const allWEFilled = tableDataWeekends
                                      .map((d) => {
                                        return d.timeSlots
                                          ? d.timeSlots.length === 0
                                          : [].length === 0;
                                      })
                                      .every((d) => d);

                                    let status = false;

                                    for (const subSpace of subSpaces) {
                                      status =
                                        checkSubSpacesNotHaveSchedule(subSpace);

                                      if (status) {
                                        break;
                                      }
                                    }

                                    if (allWDFilled || allWEFilled || status) {
                                      setShowSaveUsageModal(true);
                                    } else {
                                      saveData();
                                    }
                                  } else {
                                    setMode("View");
                                  }
                                }}
                              />
                            </Col>
                          )
                        ) : (
                          <SpinnerModal show withOverlay={false} size="sm" />
                        ))}
                      {mode == "View" && (
                        <>
                          <Col
                            className={`col-7 col-xl-5 pe-0 ${
                              styles[`weekday-row-column-two`]
                            } d-none d-lg-block order-3`}
                          >
                            {!isFetchingSchedule ? (
                              <Row>
                                {estimatedConsumption.estimatedUnit ? (
                                  <Col className="col-7 col-xl-5 pe-xl-5">
                                    <StatsView
                                      variant="bg-icon-2"
                                      labelValue={
                                        Helper.roundTo2(
                                          estimatedConsumption.estimatedUnit
                                        ) + ""
                                      }
                                      title="ESTIMATED UNITS"
                                    />
                                  </Col>
                                ) : (
                                  <></>
                                )}
                                {estimatedConsumption.percentageFromTotal ? (
                                  <Col className="col-4 col-xl-7 pe-0 pe-xl-5">
                                    <StatsView
                                      variant="bg-icon-2"
                                      labelValue={
                                        Helper.roundTo2(
                                          estimatedConsumption.percentageFromTotal
                                        ) + "%"
                                      }
                                      title="OF TOTAL UNITS"
                                    />
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </Row>
                            ) : (
                              <SpinnerModal
                                show
                                withOverlay={false}
                                size="sm"
                              />
                            )}
                          </Col>
                          {!noDevices ? (
                            <Col
                              className={`col-6 col-md-auto d-flex justify-content-end col-lg-auto ps-0 order-2 order-md-4 ${
                                styles[`update-button`]
                              } `}
                            >
                              <AppButton
                                text="Edit"
                                variant="blue"
                                iconName="drive_file_rename_outline"
                                iconOnly
                                size="medium"
                                onClick={() => {
                                  setMode((ps) =>
                                    ps === "Edit" ? "View" : "Edit"
                                  );
                                }}
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col
                    className={`col-12 mt-2 mt-sm-0 ${
                      styles[`weekday-row-column-two`]
                    } d-block d-lg-none d-inline`}
                  >
                    {!isFetchingSchedule ? (
                      <Row>
                        {mode == "View" && (
                          <>
                            {estimatedConsumption.estimatedUnit ? (
                              <Col className="mt-2 pe-5">
                                <StatsView
                                  variant="bg-icon-2"
                                  labelValue={
                                    Helper.roundTo2(
                                      estimatedConsumption.estimatedUnit
                                    ) + ""
                                  }
                                  title="ESTIMATED UNITS"
                                />
                              </Col>
                            ) : (
                              <></>
                            )}
                            {estimatedConsumption.percentageFromTotal ? (
                              <Col className="mt-2">
                                <StatsView
                                  variant="bg-icon-2"
                                  labelValue={
                                    Helper.roundTo2(
                                      estimatedConsumption.percentageFromTotal
                                    ) + "%"
                                  }
                                  title="OF TOTAL UNITS"
                                />
                              </Col>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </Row>
                    ) : (
                      <SpinnerModal show withOverlay={false} size="sm" />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {!isFetchingSchedule ? (
                <Col>
                  {!noDevices ? (
                    <div>
                      {selectedTab === 1 && (
                        <AppTable
                          data={tableDataWeekdays}
                          mode={mode}
                          onChange={(data) => {
                            setChangeCount((ps) => ps + 1);
                            setTableDataWeekdays(data);
                          }}
                        />
                      )}
                      {selectedTab === 2 && (
                        <AppTable
                          data={tableDataWeekends}
                          mode={mode}
                          onChange={(data) => {
                            setChangeCount((ps) => ps + 1);
                            setTableDataWeekends(data);
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="container-dash mt-4">
                      <Row>
                        <Col className="text-center text-light font-size-12">
                          This space does not contain any devices.{" "}
                          <Link
                            to={
                              AppRoute.SPACE_CLUSTERS +
                              `/${selectedSpaceSelect?.rootSpace.id}`
                            }
                            state={{
                              clusterId: selectedSpaceSelect?.id,
                              spaceId: selectedSpaceSelect?.rootSpace.id,
                            }}
                          >
                            Add Device
                          </Link>{" "}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ) : (
                <SpinnerModal show withOverlay={false} size="md" />
              )}
            </Row>
          </div>
        </div>
      ) : (
        <div className="container-dash mt-4">
          <Row>
            <Col className="text-center text-light font-size-12">
              You have not created any billing spaces.{" "}
              <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
            </Col>
          </Row>
        </div>
      )}
      <SpinnerModal
        show={fetchingGetSpaceClusters || loadingGetSpaceClusterWithSchedule}
      />
      <ScheduleSaveUsageModal
        show={showSaveUsageModal}
        onCancel={() => setShowSaveUsageModal(false)}
        onConfirm={() => {
          setShowSaveUsageModal(false);
          saveData();
        }}
        onClose={() => {
          setShowSaveUsageModal(false);
        }}
      />
    </>
  );
};

export default Schedule;
