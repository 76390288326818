import LoginLayout from "../../shared-components/login-layout/login-layout";
import ResetPasswordForm from "./reset-password-form/reset-password-form";

const ResetPassword = () => {
  return (
    <LoginLayout
      title="Create New Password"
      subTitle="Enter your new password below. Your new password must be deferent from the previous passwords."
      showBackButton={true}
    >
      <ResetPasswordForm />
    </LoginLayout>
  );
};

export default ResetPassword;
