import {
  IPowerConsumerUsageSchedule,
  ISchedulingTimeSlot,
} from "../oversight-core/dtos/add-schedule-dto";
import {
  ITableCellValue,
  ITableColumn,
} from "../oversight-core/ui-elements/app-table/models";

const transformScheduleDTO = (
  tableDataWeekdays: ITableColumn[],
  tableDataWeekends: ITableColumn[]
): IPowerConsumerUsageSchedule[] => {
  const idList = Array.from(
    new Set([...tableDataWeekdays, ...tableDataWeekends].map((i) => i.id))
  );

  const data: IPowerConsumerUsageSchedule[] = idList.map(
    (powerConsumerId: string) => {
      return {
        powerConsumerId,
        weekdayTimeSlots: transformTimeSlots(
          tableDataWeekdays,
          powerConsumerId
        ),
        weekendTimeSlots: transformTimeSlots(
          tableDataWeekends,
          powerConsumerId
        ),
      };
    }
  );

  return data;
};

const transformTimeSlots = (
  values: ITableColumn[],
  powerConsumerId: string
) => {
  const [timeSlots] = values
    .filter((wDay: ITableColumn) => wDay.id === powerConsumerId)
    .map((i: ITableColumn) => {
      const timeSlots: ISchedulingTimeSlot[] =
        i.timeSlots?.map((ts: ITableCellValue) => {
          return {
            ...ts,
          };
        }) || [];

      return timeSlots;
    });

  return timeSlots;
};

export default transformScheduleDTO;
