import { EDeviceTypes } from "../enums/device-types";

const findIcon = (deviceType: EDeviceTypes) => {
  switch (deviceType) {
    case EDeviceTypes.AllDevices:
      return "apps";
    case EDeviceTypes.TV:
      return "tv";
    case EDeviceTypes.Refrigerator:
      return "kitchen";
    case EDeviceTypes.MicrowaveOven:
      return "microwave";
    case EDeviceTypes.Bulb:
      return "lightbulb";
    case EDeviceTypes.Computer:
      return "computer";
    case EDeviceTypes.HomeTheater:
      return "speaker_group";
    case EDeviceTypes.Iron:
      return "iron";
    case EDeviceTypes.WashingMachine:
      return "local_laundry_service";
    case EDeviceTypes.AirConditioner:
      return "ac_unit";
    case EDeviceTypes.Fan:
      return "mode_fan";
    default:
      return "apps";
  }
};

export default findIcon;
