import { Col, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./button-with-badge.module.scss";

interface IProps {
  badgeValue?: number;
  text: string;
  icon?: MtIcon;
  onClick: () => void;
}

const ButtonWithBadge = (props: IProps) => {
  const { badgeValue, text, icon, onClick, ...rest } = props;

  return (
    <button
      {...{
        ...rest,
        className: `text-decoration-none border border-1 text-muted ${styles.button} px-3`,
      }}
      onClick={onClick}
    >
      <Row className="justify-content-center align-items-center g-2">
        <Col className={`col-auto ${badgeValue && `mb-3`}`}>
          {badgeValue ? (
            <div className={`${styles.badge}`}>{badgeValue}</div>
          ) : (
            <></>
          )}
          {icon && (
            <MaterialIcon
              size={17}
              icon={icon}
              color="#2F2A89"
              className="justify-content-center"
            />
          )}
        </Col>
        <Col className="col-auto">{text}</Col>
      </Row>
    </button>
  );
};

export default ButtonWithBadge;
