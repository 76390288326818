import MaterialIcon from "../material-icon/material-icon";
import ScheduleSelectedIndicator from "../schedule-select-indicator/schedule-select-indicator";

export interface IBreadCrumbChildSpace {
  id: string;
  name: string;
  parentSpaceId: string;
  scheduledChildSpaces?: IBreadCrumbChildSpace[];
  powerConsumersAvailable?: boolean;
  weekdayScheduled: boolean;
  weekendScheduled: boolean;
}

interface IProps {
  path: IBreadCrumbChildSpace[];
  updateSpaces: (updateParams: {
    spaceName?: string;
    parentId?: string;
    id?: string;
    childSpaces?: IBreadCrumbChildSpace[];
    path: IBreadCrumbChildSpace[];
  }) => void;
  showLastPath: boolean;
  sendSpaceName: boolean;
  showIndicators: boolean;
}

const Breadcrumb = (props: IProps) => {
  const updateSpaceHandler = (
    childSpace: IBreadCrumbChildSpace,
    path: IBreadCrumbChildSpace[]
  ) => {
    props.updateSpaces({
      spaceName: props.sendSpaceName ? childSpace.name : undefined,
      parentId: childSpace.parentSpaceId,
      id: childSpace.id,
      childSpaces: childSpace.scheduledChildSpaces || [],
      path,
    });
  };

  return (
    <div className="breadcrumb-container">
      <div className="d-flex gap-3 align-items-center">
        {(!props.showLastPath || props.path.length > 1) && (
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const length = props.path.length;
              if (length > 1) {
                const tempPath: IBreadCrumbChildSpace = props.path[length - 2];
                updateSpaceHandler(
                  tempPath,
                  props.path.slice(0, length - 1 > 0 ? length - 1 : 1)
                );
              }
            }}
          >
            <MaterialIcon
              icon={"arrow_left"}
              className="bg-primary text-white rounded-1"
            />
          </div>
        )}
        <div className="d-flex gap-0 gap-md-2 flex-wrap">
          {props.path.map((item: IBreadCrumbChildSpace, index) => {
            return (
              (props.showLastPath ||
                props.path.length - 1 !== index ||
                props.path.length === 1) && (
                <div
                  className="d-none d-md-flex gap-2 align-items-center"
                  key={index}
                >
                  <div className="d-flex align-items-center gap-1">
                    <p
                      className="mb-0 text-muted font-size-14"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        updateSpaceHandler(
                          item,
                          props.path.slice(0, index > 0 ? index + 1 : 1)
                        );
                      }}
                    >
                      {item.name}
                    </p>

                    {props.showIndicators && (
                      <div>
                        <ScheduleSelectedIndicator
                          isWeekdayFilled={item.weekdayScheduled || false}
                          isWeekendFilled={item.weekendScheduled || false}
                          powerConsumersAvailable={item.powerConsumersAvailable}
                        />
                      </div>
                    )}
                  </div>

                  <p className="mb-0 text-muted">{`>`}</p>
                </div>
              )
            );
          })}
          <div className="d-flex align-items-center gap-2 d-md-none">
            <div className="d-flex align-items-center gap-1">
              <p
                className="mb-0 text-muted font-size-14"
                onClick={() => {
                  const path = props.path;
                  const length = props.path.length;

                  const tempPath = path[length - (props.showLastPath ? 1 : 2)];
                  updateSpaceHandler(
                    tempPath,
                    path.slice(0, length > 0 ? length + 1 : 1)
                  );
                }}
              >
                {
                  props.path[props.path.length - (props.showLastPath ? 1 : 2)]
                    ?.name
                }
              </p>
              {props.showIndicators && (
                <div>
                  <ScheduleSelectedIndicator
                    isWeekdayFilled={
                      props.path[
                        props.path.length - (props.showLastPath ? 1 : 2)
                      ]?.weekdayScheduled || false
                    }
                    isWeekendFilled={
                      props.path[
                        props.path.length - (props.showLastPath ? 1 : 2)
                      ]?.weekendScheduled || false
                    }
                  />
                </div>
              )}
            </div>
            <p className="mb-0 text-muted font-size-14">{`>`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
