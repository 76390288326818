import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IBreadCrumbChildSpace } from "../../oversight-core/ui-elements/breadcrumb/breadcrumb";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import ScheduleSubSpace from "../../oversight-core/ui-elements/schedule-sub-space/schedule-sub-space-item";
import styles from "./schedule-sub-space-container.module.scss";

interface IProps {
  subSpaces: IBreadCrumbChildSpace[];
  clusterId: string;
  updateSpaces: (updateParams: {
    spaceName?: string;
    parentId?: string;
    id?: string;
    childSpaces?: IBreadCrumbChildSpace[];
    path: IBreadCrumbChildSpace[];
  }) => void;
  currentBreadCrumbPath: IBreadCrumbChildSpace[];
}

const ScheduleSubSpaceContainer = (props: IProps) => {
  const [scrollX, setScrollX] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (!containerRef.current) {
      return;
    }
    const containerWidth = containerRef.current;

    const availableScroll =
      containerWidth.scrollWidth - containerWidth.clientWidth;

    if (scrollX + containerWidth.clientWidth >= containerWidth.scrollWidth) {
      setScrollX(scrollX + availableScroll);
      return;
    }

    setScrollX(scrollX + 200);
  };

  const handlePrev = () => {
    if (scrollX <= 0) {
      return;
    }
    const scrollWidth = scrollX - 200;
    setScrollX(scrollWidth < 0 ? 0 : scrollWidth);
  };

  return (
    <>
      <div
        className={`bg-white ${
          styles[`schedule-sub-space-container`]
        } px-3 py-3`}
      >
        <Row className={`align-items-center justify-content-between`}>
          <Col className="col-1 col-sm-auto px-0 pe-sm-0 cursor-pointer d-flex align-items-center">
            <MaterialIcon
              icon="chevron_left"
              color="#69768b"
              onClick={handlePrev}
            />
          </Col>
          <Col className="col-9 col-sm px-0">
            <div className={styles.scrollContainer} ref={containerRef}>
              <div
                className={styles.scrollContent}
                style={{
                  transform: `translateX(-${scrollX}px)`,
                }}
              >
                <Row className="flex-nowrap">
                  {props.subSpaces.length !== 0 ? (
                    props.subSpaces.map((space, index) => {
                      return (
                        <Col
                          key={`${index}${space.id}`}
                          className={`col-auto mx-1`}
                        >
                          <ScheduleSubSpace
                            key={index}
                            label={space.name}
                            isWeekdayFilled={space.weekdayScheduled || false}
                            isWeekendFilled={space.weekendScheduled || false}
                            powerConsumersAvailable={
                              space.powerConsumersAvailable
                            }
                            onClick={() => {
                              setScrollX(0);
                              props.updateSpaces({
                                id: space.id,
                                childSpaces: space.scheduledChildSpaces,
                                parentId: props.clusterId,
                                path: props.currentBreadCrumbPath.concat({
                                  parentSpaceId: props.clusterId,
                                  id: space.id,
                                  name: space.name,
                                  scheduledChildSpaces:
                                    space.scheduledChildSpaces,
                                  weekdayScheduled: space.weekdayScheduled,
                                  weekendScheduled: space.weekendScheduled,
                                  powerConsumersAvailable:
                                    space.powerConsumersAvailable,
                                }),
                              });
                            }}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <Col>
                      <p className="mb-0 font-size-12 font-weight-400 text-light py-2">
                        This space doesn&apos;t have sub&#8209;spaces
                      </p>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Col>
          <Col className="col-1 col-sm-auto px-0 px-sm-1 cursor-pointer d-flex align-items-center">
            <MaterialIcon
              icon="chevron_right"
              color="#69768b"
              onClick={handleNext}
            />
          </Col>
        </Row>
      </div>

      <Row className="align-items-center justify-content-md-center justify-content-lg-end mt-3">
        <Col className="col-4 col-sm-auto ">
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <div className={`${styles[`weekdays-circle`]}`} />
            </Col>
            <Col className="ps-1 text-light font-size-11">
              Weekdays Scheduled
            </Col>
          </Row>
        </Col>
        <Col className="col-4 col-sm-auto">
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <div className={`${styles[`weekends-circle`]}`} />
            </Col>
            <Col className="ps-1 text-light font-size-11">
              Weekends Scheduled
            </Col>
          </Row>
        </Col>
        <Col className="col-4 col-sm-auto">
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <div className={`${styles[`empty-circle`]}`} />
            </Col>
            <Col className="ps-1 text-light font-size-11">Empty Space</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ScheduleSubSpaceContainer;
