import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IPowerConsumerView } from "../../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ActiveInactiveIndicator from "../../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import AppDropDown from "../../../../../../../shared/oversight-general-core/ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../../../../../../shared/oversight-general-core/ui-elements/app-toggle/app-toggle";
import styles from "./view-device-modal-header.module.scss";

interface IProps {
  device: IPowerConsumerView;
  icon: MtIcon;
  status?: "on" | "off";
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  isSocket?: boolean;
}

const ViewDeviceModalHeader = (props: IProps) => {
  const { status = "on", device, icon, isSocket } = props;
  const [isOn, setIsOn] = useState(true);

  const generalDropDownList = [
    {
      text: "Edit Device",
      onClick: () => {
        props.setShowAddUpdateModal(true);
      },
    },
    { text: "Automation" },
    {
      text: "Remove Device",
      onClick: () => {
        props.setShowRemoveDevice(true);
      },
    },
  ];

  const socketDropDownList = [
    { text: "Edit Socket" },
    { text: "Remove Socket" },
    { text: "Move Socket" },
    { text: "Disable Socket" },
    { text: "Share Access" },
  ];

  return (
    <>
      <div className={styles.large}>
        <Row className="align-items-center">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-large"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          {status && (
            <>
              <Col className="col-auto ps-2">
                <ActiveInactiveIndicator />
              </Col>
              <Col className="col-auto ms-2">
                <AppToggle
                  isOn={isOn}
                  trueValue="On"
                  falseValue="Off"
                  onSwitch={() => {
                    setIsOn((ps) => !ps);
                  }}
                />
              </Col>
            </>
          )}
          <Col>
            <AppDropDown
              items={isSocket ? socketDropDownList : generalDropDownList}
            />
          </Col>
        </Row>
      </div>
      <div className={styles.small}>
        <Row className="align-items-center">
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          <Col className="ps-2">
            <ActiveInactiveIndicator />
          </Col>
        </Row>
        <Row className="align-items-center mt-1">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-small"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className="col-auto">
            <AppToggle
              isOn={isOn}
              trueValue="On"
              falseValue="Off"
              onSwitch={() => {
                setIsOn((ps) => !ps);
              }}
            />
          </Col>
          <Col>
            <AppDropDown
              items={isSocket ? socketDropDownList : generalDropDownList}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewDeviceModalHeader;
