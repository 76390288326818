import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "./api/apiManager";
import { rtkQueryErrorLogger } from "./api/rtkQueryErrorLogger";
import authReducer from "./features/auth/auth-slice";
import filterReducer from "./features/filter/filter-slice";
import userReducer from "./features/user/user-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    filter: filterReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rootApi.middleware, rtkQueryErrorLogger]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
