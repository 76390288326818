import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useAddDeviceMutation,
  useEditDeviceMutation,
} from "../../../../../../redux/api/device/deviceAPI";
import RegisteringDeviceRequestDTO from "../../../../../../shared/oversight-core/dtos/add-device-dto";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import getDeviceTypes from "../../../../../../shared/oversight-core/utils/getDeviceType";
import {
  decimalRegex,
  noSpecialCharsNoWhitespaceStartEndAllowHyphenRegex,
  noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
  noSpecialCharsNoWhitespaceStartEndRegex,
} from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IFormInput
  extends Omit<RegisteringDeviceRequestDTO, "deviceType" | "powerUsageInWatt"> {
  deviceType: Option | [];
  powerUsageInWatt: number | undefined;
}

const defaultFormValues: IFormInput = {
  name: "",
  deviceType: [],
  deviceCategory: "",
  brand: "",
  modelNumber: "",
  serialKey: "",
  powerUsageInWatt: undefined,
};

interface IProps extends ModelContainerProps {
  spaceId: string;
  spaceClusterId: string;
  closeAddUpdateDeviceModal: () => void;
  updateCurrentState: () => void;
  device?: IPowerConsumerView;
}

const AddUpdateDeviceModal = (props: IProps) => {
  const { ...rest } = props;
  const [
    addDevice,
    { isSuccess: isSuccessAddDevice, isLoading: isLoadingAddDevice },
  ] = useAddDeviceMutation();
  const [
    editDevice,
    { isSuccess: isSuccessEditDevice, isLoading: isLoadingEditDevice },
  ] = useEditDeviceMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (props.device) {
      const device = props.device;
      setValue("name", device.name);
      setValue(
        "deviceType",
        getDeviceTypes().find((d) => d.value === device.deviceType) || []
      );
      setValue("deviceCategory", device.deviceCategory);
      setValue("brand", device.brand);
      setValue("modelNumber", device.modelNumber);
      setValue("serialKey", device.serialKey);
      setValue("powerUsageInWatt", device.powerUsageInWatt || 0);
    } else {
      setValue("powerUsageInWatt", undefined);
      reset({ ...defaultFormValues });
    }
  }, [props.device, props.show]);

  const onSubmit = (data: IFormInput) => {
    const spaceClusterId: string = props.spaceClusterId;
    const spaceId: string = props.spaceId;

    if (!props.device) {
      const deviceType = (data.deviceType as Option).value as EDeviceTypes;
      addDevice({
        spaceClusterId,
        spaceId,
        registeringDevices: [
          {
            ...data,
            deviceType,
            powerUsageInWatt: data.powerUsageInWatt ?? 0,
            serialKey: deviceType,
            deviceCategory: deviceType,
          },
        ],
      })
        .unwrap()
        .catch((error) => {
          if (error.ovstErrorCode === "OVST_CONS_0006") {
            showErrorMessage("Device Name is Already Exist");
          } else {
            showErrorMessage("Adding Device Unsuccessful");
          }
        });
    } else {
      if (props.device) {
        editDevice({
          spaceClusterId,
          spaceId,
          registeringDevice: {
            ...data,
            deviceType: (data.deviceType as Option).value as EDeviceTypes,
            id: props.device.id,
            powerUsageInWatt: data.powerUsageInWatt ?? 0,
          },
        })
          .unwrap()
          .catch(() => {
            showErrorMessage("Editing Device Unsuccessful");
          });
      }
    }
  };

  useEffect(() => {
    if (isSuccessAddDevice || isSuccessEditDevice) {
      const message = isSuccessAddDevice
        ? "Device Added to Space"
        : "Device Updated Successfully";
      showSuccessMessage(message);
      props.updateCurrentState();
      props.closeAddUpdateDeviceModal();
      reset({ ...defaultFormValues });
    }
  }, [isSuccessAddDevice, isSuccessEditDevice]);

  return (
    <ModalContainer
      {...rest}
      title={props.device ? "Edit Device" : "Add New Device"}
      size="modal-lg"
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoadingAddDevice || isLoadingEditDevice}
    >
      <>
        <AppInput
          label="Device Name"
          placeholder="Device Name"
          name="name"
          register={register("name", {
            required: "Device name is required",
            maxLength: {
              value: 20,
              message:
                "You have exceeded the maximum number of 20 characters in this field",
            },
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
        <AppSelect
          className="mt-4"
          defaultValue={""}
          placeholder="Device Type"
          options={getDeviceTypes()}
          label="Device Type"
          control={control}
          name="deviceType"
          errors={errors}
          register={register("deviceType", {
            required: "Please select the device type",
          })}
        />
        <AppInput
          className="mt-4"
          label="Brand"
          placeholder="Brand"
          name="brand"
          register={register("brand", {
            required: "Brand is required",
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
        <AppInput
          className="mt-4"
          label="Model Number"
          placeholder="Model Number"
          name="modelNumber"
          register={register("modelNumber", {
            required: "Model number is required",
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndAllowHyphenRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
        <AppInput
          className="mt-4"
          type="number"
          label="Average Energy Usage (W)"
          placeholder="Average Energy Usage (W)"
          name="powerUsageInWatt"
          register={register("powerUsageInWatt", {
            required: "Average energy usage is required",
            min: {
              value: 0.0001,
              message: "Please enter a value greater than 0",
            },
            pattern: {
              value: decimalRegex,
              message: "Please enter only upto 2 decimals",
            },
          })}
          errors={errors}
        />
      </>
    </ModalContainer>
  );
};

export default AddUpdateDeviceModal;
