import styles from "./app-modal.module.scss";

interface IProps {
  show: boolean;
  children: JSX.Element;
  size?: "modal-sm" | "modal-lg" | "modal-md";
}

const AppModal = (props: IProps) => {
  return (
    <div
      className={`${
        props.show
          ? `${styles["showing-modal"]}`
          : `${styles["not-showing-modal"]}`
      } ${styles["app-modal-backdrop"]} px-3`}
    >
      <div
        className={`${props.size || styles["modal-lg"]} ${
          styles["app-modal-container"]
        }`}
      >
        <div className={`${props.show ? "d-block" : "d-none"}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AppModal;
