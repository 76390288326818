import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useLazyViewMobileNumberQuery,
  useUpdateMobileNumberMutation,
} from "../../../../../redux/api/user/userAPI";
import { AppRoute } from "../../../interfaces/app-routes";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import { mobileNumberRegex } from "../../../utils/regex";
import { showErrorMessage } from "../../../utils/toast";
import { IChangeMobileNumberFormInput } from "../../change-mobile-number/change-mobile-number-form/change-mobile-number-form";

const defaultFormValues: IChangeMobileNumberFormInput = {
  phoneNumber: "",
};

const MobileNumberVerificationForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IChangeMobileNumberFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const [triggerViewMobileNumber, { isFetching: isFetchingViewMobileNumber }] =
    useLazyViewMobileNumberQuery();
  const [updateMobileNumber, { isLoading: isLoadingUpdateMobileNumber }] =
    useUpdateMobileNumberMutation();

  useEffect(() => {
    triggerViewMobileNumber()
      .unwrap()
      .then((response) => {
        setValue("phoneNumber", response.mobileNumber);
      })
      .catch((error) => {
        showErrorMessage(error.message || "Something went wrong");
      });
  }, [triggerViewMobileNumber]);

  const onSubmit = (data: IChangeMobileNumberFormInput) => {
    updateMobileNumber({ contactNumber: data.phoneNumber })
      .unwrap()
      .then(() => {
        navigate(AppRoute.VERIFY_MOBILE_NUMBER_OTP, {
          state: {
            number: data.phoneNumber,
            whereTo: "dashboard",
          },
        });
        reset({ ...defaultFormValues });
      })
      .catch((error) => {
        showErrorMessage(error.message || "Something went wrong");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <AppInput
              name="phoneNumber"
              placeholder="Phone Number"
              label="New Phone Number"
              register={register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Phone number is invalid",
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <AppButton
              text={`Send OTP`}
              size="medium"
              type="submit"
              isLoading={isLoadingUpdateMobileNumber}
            />
          </Col>
        </Row>
      </form>
      <SpinnerModal show={isFetchingViewMobileNumber} />
    </div>
  );
};

export default MobileNumberVerificationForm;
