import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  selectFilter,
  setFilter,
} from "../../../../../redux/features/filter/filter-slice";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { initialBreadcrumbPath } from "../space-cluster/space-cluster";

export interface SpaceBreadCrumbPath {
  clusterId: string;
  id: string;
  name: string;
}

interface IProps {
  path: SpaceBreadCrumbPath[];
}

const SpaceBreadcrumb = (props: IProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);

  const segments = pathname.split("/").filter(Boolean);

  const breadcrumbItem = (
    breadcrumbItem: SpaceBreadCrumbPath | undefined,
    to: string,
    isLast: boolean
  ) => {
    if (!breadcrumbItem) {
      return;
    }

    return (
      <div className="d-flex gap-2" key={breadcrumbItem.id}>
        <p
          className="mb-0 d-none d-md-block text-muted font-size-14"
          style={{ cursor: "pointer" }}
        >
          {breadcrumbItem.clusterId ? (
            <Link
              to={to}
              state={{
                clusterId: breadcrumbItem?.clusterId,
                spaceId: breadcrumbItem?.id,
              }}
              style={{ textDecoration: "none" }}
            >
              <span className="text-muted font-size-14">
                {breadcrumbItem?.name}
              </span>
            </Link>
          ) : (
            <span
              className="text-muted font-size-14"
              onClick={() => {
                dispatch(
                  setFilter({
                    selectedSpace: {
                      id: "",
                      clusterId: "",
                      name: "All Spaces",
                    },
                    searchText: "",
                    selectedDeviceType: "",
                    selectedGroupBy: "Space",
                  })
                );

                navigate(to);
              }}
            >
              {breadcrumbItem?.name}
            </span>
          )}
        </p>

        <p className="d-none d-md-block mb-0 text-muted">{`>`}</p>

        {isLast && (
          <p
            className="mb-0 d-block d-md-none text-muted font-size-14"
            style={{ cursor: "pointer" }}
          >
            <Link
              to={to}
              state={{
                clusterId: breadcrumbItem?.clusterId,
                spaceId: breadcrumbItem?.id,
              }}
              style={{ textDecoration: "none" }}
            >
              <span className="text-muted font-size-14">
                {breadcrumbItem?.name}
              </span>
            </Link>
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="breadcrumb-container">
      <div className="d-flex gap-3 align-items-center">
        {!filtersStore.selectedSpace.clusterId && (
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <MaterialIcon
              icon={"arrow_left"}
              className="bg-primary text-white rounded-1"
            />
          </div>
        )}
        <div className="d-flex gap-0 gap-md-2 flex-wrap">
          {[
            breadcrumbItem(
              initialBreadcrumbPath,
              "/dashboard/space-clusters",
              props.path.length === 1 ? true : false
            ),
          ].concat(
            props.path.slice(0, -1).map((segment, index) => {
              const url = `/${segments.slice(0, index + 3).join("/")}`;

              return breadcrumbItem(
                segment,
                url,
                props.path.slice(0, -1).length - 1 === index ? true : false
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SpaceBreadcrumb;
