import { Col, Row } from "react-bootstrap";
import styles from "./stats-view.module.scss";

interface IProps {
  variant: "bg-icon-2" | "bg-icon-3" | "bg-icon-4" | "bg-icon-5" | "bg-icon-6";
  labelValue: string;
  title: string;
  fontStyle?: "normal" | "bold" | "small";
  columnClass?: string;
  fontSize?: number;
}

const StatsView = (props: IProps) => {
  const { variant, labelValue, title, columnClass, fontStyle = "bold" } = props;
  return (
    <Row className="align-items-center">
      <Col xs={9} md={9} xl={9}>
        <div
          className={`${styles.label} ${variant} ${styles[fontStyle]} px-3 px-md-2`}
          style={{ fontSize: props.fontSize }}
        >
          {labelValue}
        </div>
      </Col>
      <Col className={`${styles.title} ${columnClass} col-3 ps-1 pe-0`}>
        {title}
      </Col>
    </Row>
  );
};

export default StatsView;
