import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLazyGetSpacesQuery } from "../../../../redux/api/space/spaceAPI";
import { selectFilter } from "../../../../redux/features/filter/filter-slice";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import { ISpaceView } from "../../../oversight-core/interfaces/entities/space";
import FilteringSidePanel from "../../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../../oversight-core/ui-elements/app-select/app-select";
import Search from "../../../oversight-core/ui-elements/search/search";
import SpinnerModal from "../../../oversight-core/ui-elements/spinner/spinner";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../oversight-core/utils/getDeviceType";
import ButtonWithBadge from "../../ui-elements/button-with-badge/button-with-badge";
import SelectedFiltersView from "../selected-filters-view/selected-filters-view";
import SpaceSelectorDropdown from "../space-selector-dropdown/space-selector-dropdown";

export interface ISelectedSpace {
  id: string;
  name: string;
  clusterId: string;
}

const defaultSpace: ISelectedSpace = {
  id: "",
  name: "All Spaces",
  clusterId: "",
};

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.AllDevices, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

const groupBy = [
  { label: "Space", value: "Space" },
  { label: "Type", value: "Type" },
];

interface IProps {
  updateFilters: (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string
  ) => void;
  isLoading: boolean;
}

const SpaceDeviceFilters = (props: IProps) => {
  const filtersStore = useSelector(selectFilter);
  const { updateFilters, isLoading } = props;
  const [resetDeviceSearchText, setResetDeviceSearchText] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    ...filtersStore.selectedSpace,
  });
  const [searchText, setSearchText] = useState(filtersStore.searchText);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...(deviceTypes.find(
      (deviceType) => deviceType.value === filtersStore.selectedDeviceType
    ) || defaultDeviceType),
  });
  const [selectedGroupBy, setSelectedGroupBy] = useState<Option>({
    ...(groupBy.find(
      (groupByItem) => groupByItem.value === filtersStore.selectedGroupBy
    ) || groupBy[0]),
  });
  const [badgeValue, setBadgeValue] = useState(0);

  const [triggerGetSpaces, { isFetching: loadingSpaces }] =
    useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        const tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        setSpaceClusters(tempArray);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  const handleSearchChange = (text: string) => {
    setSearchText(text);

    updateFilters(
      {
        clusterId: selectedSpace.clusterId,
        id: selectedSpace.id,
        name: selectedSpace.name,
      },
      text,
      selectedDeviceType.value,
      selectedGroupBy.value
    );

    if (
      selectedSpace.clusterId &&
      selectedDeviceType.value &&
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(3);
    } else if (
      (selectedSpace.clusterId && selectedDeviceType.value) ||
      (selectedDeviceType.value && selectedGroupBy.value !== "Space") ||
      (selectedSpace.clusterId && selectedGroupBy.value !== "Space")
    ) {
      setBadgeValue(2);
    } else if (
      selectedSpace.clusterId ||
      selectedDeviceType.value ||
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  };

  const updateBadgeValue = () => {
    if (
      selectedSpace.clusterId &&
      selectedDeviceType.value &&
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(3);
    } else if (
      (selectedSpace.clusterId && selectedDeviceType.value) ||
      (selectedDeviceType.value && selectedGroupBy.value !== "Space") ||
      (selectedSpace.clusterId && selectedGroupBy.value !== "Space")
    ) {
      setBadgeValue(2);
    } else if (
      selectedSpace.clusterId ||
      selectedDeviceType.value ||
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <Search
            handleSearchChange={handleSearchChange}
            placeholder="Search Device Name"
            reset={resetDeviceSearchText}
          />
        </Col>
        <Col>
          <Row className="justify-content-end align-items-center">
            <Col className="col-auto">
              <SelectedFiltersView
                spaceName={filtersStore.selectedSpace.name}
                filters={[
                  {
                    icon: filtersStore.selectedDeviceType
                      ? (filtersStore.selectedDeviceType as EDeviceTypes)
                      : EDeviceTypes.AllDevices,
                    filterName:
                      filtersStore.selectedDeviceType || "All Devices",
                  },
                ]}
              />
            </Col>
            <Col className="col-auto">
              <ButtonWithBadge
                text="Filter"
                icon="filter_alt"
                onClick={() => {
                  setShowFilters(true);
                }}
                badgeValue={badgeValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <FilteringSidePanel
        isOpen={showFilters && !isLoading}
        onClose={() => setShowFilters(false)}
        onFilter={() => {
          setShowFilters(false);
          updateFilters(
            {
              clusterId: selectedSpace.clusterId,
              id: selectedSpace.id,
              name: selectedSpace.name,
            },
            searchText,
            selectedDeviceType.value,
            selectedGroupBy.value
          );
          updateBadgeValue();
        }}
        onClearAll={() => {
          updateFilters(
            {
              clusterId: "",
              id: "",
              name: "All Spaces",
            },
            "",
            "",
            "Space"
          );
          setResetDeviceSearchText((ps) => !ps);
          setSelectedSpace({ ...defaultSpace });
          setSelectedDeviceType({ ...defaultDeviceType });
          setSelectedGroupBy({ ...groupBy[0] });
          setBadgeValue(0);
        }}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={[
              { clusterId: "", id: "", name: "All Spaces", childSpaces: [] },
              ...spaceClusters.map((space) => {
                return {
                  clusterId: space.clusterId,
                  id: space.id,
                  name: space.name,
                  childSpaces: space.childSpaces,
                };
              }),
            ]}
            disabled={false}
            selectedSubSpace={selectedSpace.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />

          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) =>
              setSelectedDeviceType(selectedDeviceTypeOption)
            }
            disabled={selectedGroupBy.value === "Type"}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />

          <AppSelect
            label="Group By"
            options={groupBy}
            className="mt-4"
            selectedValue={selectedGroupBy}
            onChangeOption={(selectedGroupByOption) => {
              setSelectedGroupBy(selectedGroupByOption);
            }}
            disabled={selectedDeviceType.value ? true : false}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
      <SpinnerModal show={loadingSpaces} />
    </>
  );
};

export default SpaceDeviceFilters;
