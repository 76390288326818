import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useLazyGetConsumedSpaceClusterByYearAndMonthQuery,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetSpaceClustersXYQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import { useLazyGetEnergyConsumptionQuery } from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetEnergyGenerationDataQuery } from "../../../redux/api/solar/solarAPI";
import { EConsumptionValueComparison } from "../../../shared/oversight-core/enums/consumption-comparison";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ConsumptionDataView } from "../../../shared/oversight-core/interfaces/entities/consumption-data-view";
import IExtendedEnergyConsumers from "../../../shared/oversight-core/interfaces/extended-energy-consumers";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import {
  ISpaceCluster,
  ViewConsumedSpaceClusterByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/response-dto/dashboard-api-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/response-dto/view-energy-generation-response-dto";
import HighPowerDevices from "../../../shared/oversight-core/shared-components/dashboard-widgets/high-power-devices/high-power-devices";
import Info from "../../../shared/oversight-core/shared-components/dashboard-widgets/info/info";
import Usage from "../../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
}

const defaultPredictionThisMonth = {
  bill: 0,
  unit: 0,
  comparisonWithLastMonth: EConsumptionValueComparison.NOT_MEASURABLE,
};

const defaultActualLastMonth = {
  bill: 0,
  unit: 0,
};

const Home = () => {
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [selectedSpaceCluster, setSelectedSpaceCluster] =
    useState<ISpaceCluster>();

  const [predictionThisMonth, setPredictionThisMonth] = useState({
    ...defaultPredictionThisMonth,
  });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultActualLastMonth,
  });

  const [consumers, setConsumers] = useState<IExtendedEnergyConsumers[]>([]);

  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
  });
  const [isSolarAvailable, setIsSolarAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggerGetSpaceClusters, { isFetching: getSpaceClustersFetching }] =
    useLazyGetSpaceClustersXYQuery();
  const [triggerGetUsagePredictionThisMonth] =
    useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [triggerGetActualUsageThisMonth] =
    useLazyGetConsumedSpaceClusterByYearAndMonthQuery();
  const [triggerViewPowerConsumersWithHighConsumption] =
    useLazyViewPowerConsumersWithHighConsumptionQuery();
  const [triggerViewEnergyGenerationData] =
    useLazyGetEnergyGenerationDataQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    { data: getEnergyConsumption, isSuccess: isSuccessEnergyConsumption },
  ] = useLazyGetEnergyConsumptionQuery();

  useEffect(() => {
    setSpaceClusters([]);
    triggerGetSpaceClusters()
      .then((res) => {
        setSpaceClusters(res.data?.spaceClusters || []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedSpaceCluster?.id && selectedDate) {
      const year = parseInt(format(selectedDate, "yyyy"));
      const viewEnergyAs: EEnergyViewType =
        selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
      const viewConsumptionAs: EConsumptionViewTypes =
        EConsumptionViewTypes.ALL;

      setChartData((ps) => ({ ...ps, grid: [], solar: [] }));
      setLoading(true);

      triggerViewActualConsumptionByYearQuery({
        spaceClusterId: selectedSpaceCluster.id,
        year,
        viewEnergyAs,
        viewConsumptionAs,
      }).finally(() => setLoading(false));

      setIsSolarAvailable(selectedSpaceCluster?.powerGeneratorIds?.length > 0);

      if (
        selectedButton === 1 &&
        selectedSpaceCluster.powerGeneratorIds?.length > 0
      ) {
        triggerViewEnergyGenerationData({
          powerGeneratorId: selectedSpaceCluster.powerGeneratorIds[0] || "",
          year: selectedDate.getFullYear(),
        })
          .unwrap()
          .then((res: ViewEnergyGenerationResponseDTO) => {
            setChartData((ps) => {
              ps.solar =
                res.generatedEnergy || Array.from({ length: 12 }, () => 0);
              ps.months = res.months || [];
              return { ...ps };
            });
          })
          .catch((error) => {
            if (
              error.status === 404 &&
              error.ovstErrorCode === OvstErrorCode.OVST_0028
            ) {
              showErrorMessage("Sorry, An error occurred");
              return;
            }
          })
          .finally(() => setLoading(false));
      }
      setChartData((ps) => {
        ps.solar = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    } else {
      setChartData({
        consumptionType: "UNITS",
        year: 0,
        months: [],
        solar: [],
        grid: [],
      });
    }
  }, [
    selectedSpaceCluster?.id,
    selectedSpaceCluster?.powerGeneratorIds,
    selectedDate,
    selectedButton,
    triggerViewActualConsumptionByYearQuery,
  ]);

  useEffect(() => {
    if (isSuccessEnergyConsumption && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.predictedConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.scheduledEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.scheduledEnergySummary
                    ?.energyBill || 0
            );
            return previousValue;
          },
          {
            months: [],
            predictedConsumption: [],
            actualConsumption: [],
          }
        );

      setChartData((ps) => {
        ps.grid = data.actualConsumption;
        ps.months = data.months;
        return { ...ps };
      });
    } else {
      setChartData((ps) => {
        ps.grid = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [isSuccessEnergyConsumption, getEnergyConsumption]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      setSelectedSpaceCluster(spaceClusters[0]);
    }
  }, [spaceClusters]);

  useEffect(() => {
    if (selectedSpaceCluster?.id) {
      setLoading(true);
      setConsumers([]);
      setPredictionThisMonth({ ...defaultPredictionThisMonth });
      setActualThisMonth({ ...defaultActualLastMonth });
      triggerGetUsagePredictionThisMonth(selectedSpaceCluster.id)
        .unwrap()
        .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
          setPredictionThisMonth({
            bill: res.scheduledSpaceClusterEnergy.energyBill || 0,
            unit: res.scheduledSpaceClusterEnergy.energyInUnits || 0,
            comparisonWithLastMonth:
              res.comparedToPreviousMonthConsumedEnergy ||
              EConsumptionValueComparison.NOT_MEASURABLE,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
      triggerGetActualUsageThisMonth({
        spaceClusterId: selectedSpaceCluster.id,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
      })
        .unwrap()
        .then((res: ViewConsumedSpaceClusterByYearAndMonthResponseDTO) => {
          setActualThisMonth({
            bill: res.energy.energyBill || 0,
            unit: res.energy.energyInUnits || 0,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));

      triggerViewPowerConsumersWithHighConsumption({
        clusterId: selectedSpaceCluster.id,
        sortingOrder: ESortOrder.DESC,
        limit: 6,
      })
        .unwrap()
        .then((res) => {
          const extendedPowerConsumers = res.powerConsumers.map(
            (powerConsumer) => {
              const pathObject = res.devicePaths[powerConsumer.id];
              let path = ``;
              pathObject.ancestorSpaces
                .slice()
                .reverse()
                .forEach((hierarchy, hierarchyIndex) => {
                  path +=
                    pathObject.ancestorSpaces.length - 1 === hierarchyIndex
                      ? `${hierarchy.name}`
                      : `${hierarchy.name} > `;
                });
              return { ...powerConsumer, path };
            }
          );
          setConsumers(extendedPowerConsumers);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    }
  }, [selectedSpaceCluster?.id]);

  const monthsList = chartData.months.map(
    (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
  );

  return (
    <div>
      {spaceClusters.length > 0 ? (
        <div>
          <Row>
            <Col xs={12}>
              <Row className="align-items-center">
                <Col xs="auto">
                  <label
                    className={`mb-0 me-2 jakarta font-weight-600 font-size-10 text-dark`}
                  >
                    Billing <br /> Space
                  </label>
                </Col>
                <Col
                  xs="auto"
                  md={4}
                  className="ps-0 flex-grow-1 flex-lg-grow-0"
                >
                  <AppSelect
                    placeholder="Select Solar"
                    selectedValue={{
                      value: selectedSpaceCluster?.id || "",
                      label: selectedSpaceCluster?.label || "",
                      data: selectedSpaceCluster,
                    }}
                    options={
                      spaceClusters
                        ? spaceClusters.map((spaceCluster) => {
                            return {
                              value: spaceCluster.id,
                              label: spaceCluster.label,
                              data: spaceCluster,
                            };
                          })
                        : []
                    }
                    onChangeOption={(selectedOption) => {
                      setSelectedSpaceCluster(
                        selectedOption.data as ISpaceCluster
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <>
            <Row className="mt-4">
              <Col
                xs={12}
                lg={6}
                xl={3}
                className="order-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="h-50 pb-1">
                  <Usage
                    title="Usage Prediction this Month"
                    cost={predictionThisMonth.bill}
                    units={predictionThisMonth.unit}
                    comparisonWithLastMonth={
                      predictionThisMonth.comparisonWithLastMonth
                    }
                  />
                </div>
                <div className="pt-1 h-50">
                  <Usage
                    title="Actual Usage Last Month"
                    cost={actualThisMonth.bill}
                    units={actualThisMonth.unit}
                  />
                </div>
              </Col>
              <Col
                xs={12}
                lg={12}
                xl={6}
                className="order-2 order-lg-3  order-xl-2 mt-3 mt-lg-3 mt-xl-0 "
              >
                <HighPowerDevices
                  consumers={consumers}
                  title="Devices with highest energy consumption"
                />
              </Col>
              <Col
                xs={12}
                lg={6}
                xl={3}
                className="order-3 order-lg-2 order-xl-3 mt-3 mt-lg-0"
              >
                <Info />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <div className="w-100 container-white">
                  <Row className="align-items-center justify-content-center justify-content-md-end mb-4">
                    <Col
                      xs={{ order: 3 }}
                      md={{ order: 1 }}
                      className="col-12 col-sm-4 col-md-4 col-lg-2 text-end"
                    >
                      <AppTabs
                        buttons={[
                          {
                            buttonName: "Units",
                            selectedColor: "purpleColor",
                            id: 1,
                          },
                          {
                            buttonName: "Bill",
                            selectedColor: "purpleColor",
                            id: 2,
                          },
                        ]}
                        onChange={(id) => setSelectedButton(id)}
                        selectedTabId={selectedButton}
                      />
                    </Col>
                    <Col
                      xs={{ order: 2 }}
                      md={{ order: 3 }}
                      className="col-12 col-md-3 my-3 my-md-0"
                    >
                      <AppDatePicker
                        dateFormat="yyyy"
                        selectedDate={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        monthYearPicker={false}
                        yearPicker={true}
                      />
                    </Col>
                  </Row>
                  <div>
                    {selectedButton === 1 ? (
                      <AreaChart
                        borderColor1="#18A1AA"
                        borderColor2="#29CC39"
                        backgroundColor1="#DFFDFB4D"
                        backgroundColor2="#29CC3917"
                        label1="Usage from grid"
                        label2="Solar Generation"
                        yAxesUnit={"Units"}
                        labels={monthsList}
                        data1={chartData.grid}
                        data2={chartData.solar}
                        hasSecondDataSet={false}
                      />
                    ) : (
                      <AreaChart
                        borderColor1="#18A1AA"
                        backgroundColor1="#DFFDFB4D"
                        label1="Usage from grid"
                        yAxesUnit={"Rs."}
                        labels={monthsList}
                        data1={chartData.grid}
                        hasSecondDataSet={false}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        </div>
      ) : (
        <div className="container-dash mt-4">
          <Row>
            <Col className="text-center text-light font-size-12">
              You have not created any billing spaces.{" "}
              <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
            </Col>
          </Row>
        </div>
      )}
      <SpinnerModal show={loading || getSpaceClustersFetching} />
    </div>
  );
};

export default Home;
