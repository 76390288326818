import AddScheduleRequestDTO from "../../../shared/oversight-core/dtos/add-schedule-dto";
import ViewScheduleResponseDTO from "../../../shared/oversight-core/response-dto/view-schedule-dto";
import { ViewScheduleWithAverageUnitsBySpaceClusterIdentity } from "../../../shared/oversight-core/response-dto/view-schedule-with-average-units-by-space-cluster-identity";
import ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO from "../../../shared/oversight-core/response-dto/view-scheduled-space-cluster-with-scheduled-space-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import AddSemiAutomatedScheduledRequestDTO from "../../../shared/oversight-general-core/dtos/add-semi-automated-scheduled-request-dto";
import AddSemiAutomatedScheduledResponseDTO from "../../../shared/oversight-general-core/dtos/add-semi-automated-scheduled-response-dto";
import ViewPowerConsumerScheduleAvailabilityResponseDTO from "../../../shared/oversight-general-core/dtos/view-power-consumer-schedule-availability-response-dto";
import ViewSemiAutomationScheduleResponseDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-response-dto";
import ViewSubSpaceClustersWithScheduleAvailabilityResponseDTO from "../../../shared/oversight-general-core/dtos/view-sub-space-clusters-with-schedule-availability-response-dto";
import { rootApi } from "../apiManager";

export const scheduleAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSemiAutomationSchedule: builder.mutation<
      AddSemiAutomatedScheduledResponseDTO,
      AddSemiAutomatedScheduledRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage/semi-automated/schedule`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateSemiAutomationSchedule: builder.mutation<
      AddSemiAutomatedScheduledResponseDTO,
      AddSemiAutomatedScheduledRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage-schedule/semi-automated/${data.semiAutomatedScheduleId}/update`,
          method: "PUT",
          body: data,
        };
      },
    }),
    removeSemiAutomationSchedule: builder.mutation<
      void,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        semiAutomatedScheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, semiAutomatedScheduleId }) {
        return {
          url: `/space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/semi-automated/${semiAutomatedScheduleId}/delete`,
          method: "DELETE",
        };
      },
    }),
    viewSubSpaceClusterWithScheduleAvailability: builder.query<
      ViewSubSpaceClustersWithScheduleAvailabilityResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        requiredDepth?: number;
        date: string;
        category?: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, requiredDepth, date, category }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/availability/view`,
          method: "GET",
          params: { requiredDepth, date, category },
        };
      },
    }),
    viewSemiAutomatedScheduledSpaceCluster: builder.query<
      ViewSemiAutomationScheduleResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        semiAutomatedScheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, semiAutomatedScheduleId }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/${semiAutomatedScheduleId}/view`,
          method: "GET",
        };
      },
    }),
    viewPowerConsumersScheduleAvailabilityByDateTime: builder.query<
      ViewPowerConsumerScheduleAvailabilityResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        requiredDepth?: number;
        fromDate: string;
        toDate: string;
        category?: string;
      }
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        subRootSpaceId,
        requiredDepth,
        fromDate,
        toDate,
        category,
      }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/availability`,
          method: "GET",
          params: { requiredDepth, fromDate, toDate, category },
        };
      },
    }),
    viewSchedule: builder.query<
      ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO,
      { spaceClusterId: string; spaceId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${spaceId}/power-consumer-usage-schedule/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    updateSchedule: builder.query<
      ViewScheduleResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        year: number;
        month: number;
        data: AddScheduleRequestDTO;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, year, month, data }) {
        return {
          url: `space-cluster/${spaceClusterId}/space/${spaceId}/power-consumer-usage/schedule?year=${year}&month=${month}`,
          method: "POST",
          body: data,
        };
      },
    }),
    viewScheduleWithAverageUnits: builder.query<
      ViewScheduleWithAverageUnitsBySpaceClusterIdentity,
      { spaceClusterId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/power-consumer-usage-schedule/with-average-weekly-energy/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddSemiAutomationScheduleMutation,
  useUpdateSemiAutomationScheduleMutation,
  useRemoveSemiAutomationScheduleMutation,
  useLazyViewSubSpaceClusterWithScheduleAvailabilityQuery,
  useLazyViewSemiAutomatedScheduledSpaceClusterQuery,
  useLazyViewPowerConsumersScheduleAvailabilityByDateTimeQuery,
  useLazyViewScheduleQuery,
  useLazyUpdateScheduleQuery,
  useLazyViewScheduleWithAverageUnitsQuery,
} = scheduleAPI;
