import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetSpaceHierarchyQuery } from "../../../../redux/api/space/spaceAPI";
import AddUpdateDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/add-update-device-modal/add-update-device-model";
import RemoveDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/remove-device-modal/remove-device-modal";
import ViewDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/view-device-modal/view-device-modal";
import { IPowerConsumerView } from "../../../oversight-core/interfaces/entities/power-consumer";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import { ISpaceClusterShallowHierarchyView } from "../../interfaces/space-hierarchy";
import AppDropDown from "../../ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../ui-elements/app-toggle/app-toggle";
import styles from "./list-card.module.scss";

interface IProps {
  backgroundColor?: string;
  deviceStatus: boolean;
  isDevicesOnly: boolean;
  deleteDevice: (clusterId: string, spaceId: string, deviceId: string) => void;
  updateCurrentState: () => void;
  device: IPowerConsumerView;
  mainSpaceClusterId: string;
  mainSpaceId: string;
  path?: ISpaceClusterShallowHierarchyView;
  showSocketInformation?: boolean;
}

const ListCard = (props: IProps) => {
  const {
    backgroundColor,
    deviceStatus,
    isDevicesOnly,
    deleteDevice,
    updateCurrentState,
    device,
    mainSpaceClusterId,
    mainSpaceId,
    path,
    showSocketInformation,
  } = props;

  const [showRemoveDevice, setShowRemoveDevice] = useState(false);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [showViewDeviceModal, setShowViewDeviceModal] = useState(false);
  const [breadCrumbPath, setBreadCrumbPath] = useState("");
  const [isOn, setIsOn] = useState(true);

  const closeAddUpdateDeviceModal = () => {
    setShowAddUpdateModal(false);
  };

  const [triggerGetHierarchy] = useLazyGetSpaceHierarchyQuery();

  useEffect(() => {
    if (!path) {
      triggerGetHierarchy({
        clusterId: mainSpaceClusterId,
        spaceId: mainSpaceId,
      })
        .unwrap()
        .then((response) => {
          let paths = "";
          response.ancestorSpaces
            .slice()
            .reverse()
            .forEach((ancestor, index) => {
              paths +=
                index !== response.ancestorSpaces.length - 1
                  ? `${ancestor.name} > `
                  : `${ancestor.name}`;
            });

          setBreadCrumbPath(paths);
        })
        .catch((error) => {
          console.log(error);
          setBreadCrumbPath("");
        });
    } else {
      let paths = "";
      path.ancestorSpaces
        .slice()
        .reverse()
        .forEach((ancestor, index) => {
          paths +=
            index !== path.ancestorSpaces.length - 1
              ? `${ancestor.name} > `
              : `${ancestor.name}`;
        });

      setBreadCrumbPath(paths);
    }
  }, [triggerGetHierarchy, path]);

  return (
    <>
      <div
        className={`${styles.listCardContainer} p-2 ps-4 cursor-pointer`}
        style={{ backgroundColor: backgroundColor }}
        onClick={() => setShowViewDeviceModal(true)}
      >
        <Row className="align-items-center">
          <Col className={`${device.deviceType} p-3 rounded col-auto`}>
            <MaterialIcon icon={findIcon(device.deviceType)} />
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col
                className={`${styles.deviceName} ${
                  isDevicesOnly ? `col-auto` : ``
                } pe-1`}
              >
                {device.name}
              </Col>
              {isDevicesOnly && (
                <Col className="ps-1">
                  <Row className="align-items-center flex-nowrap">
                    <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                      {device.powerUsageInWatt}W
                    </Col>
                    <Col className="ps-1">
                      <div
                        className={`${styles.deviceStatus} ${
                          deviceStatus
                            ? `${styles.deviceOnStatus}`
                            : `${styles.deviceOffStatus}`
                        }`}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
              {isDevicesOnly && (
                <Col className="font-size-11 text-light font-weight-400">
                  {breadCrumbPath}
                </Col>
              )}
              {!isDevicesOnly && (
                <Col>
                  <Row className="align-items-center">
                    <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                      {device.powerUsageInWatt}W
                    </Col>
                    <Col>
                      <div
                        className={`${
                          deviceStatus
                            ? `${styles.deviceOnStatus}`
                            : `${styles.deviceOffStatus}`
                        }`}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            className={`pe-4 ${
              showSocketInformation ? "col-12 col-sm-auto" : "col-auto"
            } mt-3 mt-sm-0`}
          >
            <Row className="align-items-center gap-1 flex-nowrap">
              {showSocketInformation && (
                <Col className="col col-sm-auto">
                  <Row className="align-items-center flex-nowrap">
                    <Col className="col-auto pe-0">
                      <MaterialIcon icon="smart_outlet" size={12} />
                    </Col>
                    <Col className="font-size-12 text-light font-weight-400 ps-1">
                      Bed Room Outlet
                    </Col>
                  </Row>
                </Col>
              )}
              <Col className="col-auto">
                <AppToggle
                  isOn={isOn}
                  onSwitch={() => {
                    setIsOn((ps) => !ps);
                  }}
                  size="sm"
                />
              </Col>
              <Col className={`p-1 ${styles.dropdown} col-auto`}>
                <AppDropDown
                  items={[
                    {
                      text: "Edit Device",
                      onClick: () => {
                        setShowAddUpdateModal(true);
                      },
                    },
                    {
                      text: "Remove Device",
                      onClick: () => {
                        setShowRemoveDevice(true);
                      },
                    },
                    {
                      text: " Move Device",
                      onClick: () => {
                        console.log();
                      },
                    },
                    {
                      text: "Disable Device",
                      onClick: () => {
                        console.log();
                      },
                    },
                    {
                      text: "Automation",
                      onClick: () => {
                        console.log();
                      },
                    },
                    {
                      text: "Share Access",
                      onClick: () => {
                        console.log();
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ViewDeviceModal
        device={props.device}
        show={showViewDeviceModal}
        setShowAddUpdateModal={setShowAddUpdateModal}
        setShowRemoveDevice={setShowRemoveDevice}
        onClose={() => {
          setShowViewDeviceModal(false);
        }}
        icon={findIcon(props.device.deviceType)}
      />
      <AddUpdateDeviceModal
        show={showAddUpdateModal}
        onClose={() => setShowAddUpdateModal(false)}
        onCancel={() => setShowAddUpdateModal(false)}
        spaceClusterId={mainSpaceClusterId}
        spaceId={mainSpaceId}
        closeAddUpdateDeviceModal={closeAddUpdateDeviceModal}
        updateCurrentState={updateCurrentState}
        device={device}
      />
      <RemoveDeviceModal
        show={showRemoveDevice}
        deviceName={device.name}
        onClose={() => setShowRemoveDevice(false)}
        onCancel={() => setShowRemoveDevice(false)}
        onConfirm={() => {
          deleteDevice(mainSpaceClusterId, mainSpaceId, device.id);
          setShowRemoveDevice(false);
          updateCurrentState();
        }}
      />
    </>
  );
};

export default ListCard;
