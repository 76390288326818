import LoginLayout from "../../shared-components/login-layout/login-layout";
import ChangeMobileNumberForm from "./change-mobile-number-form/change-mobile-number-form";

const ChangeMobileNumber = () => {
  return (
    <LoginLayout
      title="Change Mobile Number"
      subTitle="Enter new phone number for the account."
      showBackButton={true}
    >
      <ChangeMobileNumberForm />
    </LoginLayout>
  );
};

export default ChangeMobileNumber;
