import { Col, Row } from "react-bootstrap";
import ScheduleSelectedIndicator from "../schedule-select-indicator/schedule-select-indicator";
import styles from "./schedule-sub-space-item.module.scss";

interface IProps {
  label: string;
  powerConsumersAvailable?: boolean;
  isWeekdayFilled: boolean;
  isWeekendFilled: boolean;
  onClick?: () => void;
}

const ScheduleSubSpace = (props: IProps) => {
  const { isWeekdayFilled, isWeekendFilled, onClick, label } = props;
  return (
    <Row
      className={`align-items-center cursor-pointer ${
        styles[`schedule-sub-space`]
      } bg-gray-3 py-2 px-1 justify-content-center flex-nowrap`}
      onClick={onClick && onClick}
    >
      <Col className="pe-1 col-auto">
        <p className="mb-0 font-size-12 text-light font-weight-500">{label}</p>
      </Col>
      <Col className="col-auto ps-0">
        <ScheduleSelectedIndicator
          isWeekdayFilled={isWeekdayFilled}
          isWeekendFilled={isWeekendFilled}
          powerConsumersAvailable={props.powerConsumersAvailable}
        />
      </Col>
    </Row>
  );
};

export default ScheduleSubSpace;
